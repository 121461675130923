import { ExperimentData } from '../context/NavContext';
import contentStorage from './contentStorage';

export const startTestSessionStorage = (
  experiment: ExperimentData,
  putMetric: () => Promise<void>
): void => {
  const session = contentStorage.getExperimentSession(experiment.shortname);

  if (!session && experiment?.shortname && experiment?.entityId) {
    contentStorage.setExperimentSession(experiment.shortname, experiment.entityId);
    putMetric();
  }
};
