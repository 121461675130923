import { SvgProps } from '../SvgRendererTypes';

export const GiveBackDiscountIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Give Back Discount</title>
      <path
        fill={fillColor}
        d="M20.67 12.87C20.05 12.19 18.49 11.24 17.02 12.51L15.39 14.01L8.93 14C7.38 14 6.25 15.07 6.17 15.15L3 18.74L3.75 19.4L6.91 15.84C6.92 15.83 7.81 15 8.94 15L14.32 15.01L13.3 15.96H10.88V16.96H13.7L17.71 13.26C18.43 12.64 19.15 12.93 19.58 13.23L14.03 18.57H8.92L6.45 21.34L7.16 22L9.34 19.56H14.41L21 13.23L20.67 12.87Z"
      />
      <path
        fill={fillColor}
        d="M11.4 6.73C11.4 5.77 10.62 5 9.66 5C8.7 5 7.92 5.77 7.92 6.73C7.92 7.69 8.7 8.46 9.66 8.46C10.62 8.46 11.4 7.69 11.4 6.73ZM8.92 6.73C8.92 6.32 9.25 6 9.66 6C10.07 6 10.4 6.33 10.4 6.73C10.4 7.13 10.07 7.46 9.66 7.46C9.25 7.46 8.92 7.13 8.92 6.73Z"
      />
      <path
        fill={fillColor}
        d="M14.12 12.7C15.08 12.7 15.85 11.92 15.85 10.97C15.85 10.01 15.07 9.24 14.12 9.24C13.16 9.24 12.38 10.02 12.38 10.97C12.39 11.92 13.16 12.7 14.12 12.7ZM14.12 10.23C14.53 10.23 14.85 10.56 14.85 10.96C14.85 11.36 14.52 11.69 14.12 11.69C13.71 11.69 13.38 11.36 13.38 10.96C13.39 10.56 13.72 10.23 14.12 10.23Z"
      />
      <path
        fill={fillColor}
        d="M8.89 12.29C9.02 12.29 9.15 12.24 9.25 12.14L14.72 6.5C14.91 6.3 14.91 5.99 14.71 5.79C14.51 5.59 14.2 5.6 14 5.8L8.53 11.44C8.34 11.64 8.34 11.95 8.54 12.15C8.64 12.24 8.76 12.29 8.89 12.29Z"
      />
      <path
        fill={fillColor}
        d="M7.31 13.31C6.19 12.17 5.57 10.66 5.57 9.06C5.57 7.44 6.2 5.92 7.35 4.77C8.5 3.63 10.02 3 11.64 3C13.26 3 14.79 3.63 15.93 4.78C17.08 5.93 17.71 7.45 17.71 9.07C17.71 9.78 17.58 10.47 17.35 11.12C17.69 11.01 18.06 10.93 18.46 10.92C19.1 8.56 18.5 5.93 16.64 4.08C15.26 2.69 13.45 2 11.64 2C9.83 2 8.02 2.69 6.64 4.07C3.97 6.73 3.89 11 6.38 13.77C6.65 13.62 6.97 13.45 7.31 13.31Z"
      />
    </svg>
  );
};
