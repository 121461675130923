import { SvgProps } from '../SvgRendererTypes';

export const HomeIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Home Icon</title>
      <path
        fill={fillColor}
        d="M20.7 9.7L12 2 3.3 9.7c-.1.1-.2.2-.2.3-.1.2-.1.3-.1.4V21c0 .3.1.5.3.7.2.2.4.3.7.3h16c.3 0 .5-.1.7-.3s.3-.4.3-.7V10.4c0-.1 0-.3-.1-.4 0-.1-.1-.2-.2-.3zM10 21v-6h4v6h-4zm10 0h-5v-6c0-.3-.1-.5-.3-.7s-.4-.3-.7-.3h-4c-.3 0-.5.1-.7.3-.2.2-.3.4-.3.7v6H4V10.4l8-7.1 8 7.1V21z"
      />
    </svg>
  );
};
