import { ContentProps } from '../context/NavContext';
import { Defaults, GlobalNavContentURLs, SESSION_STORAGE_CONTENT_KEY } from '../static/constants';

class ContentStorage {
  env: string;
  currentLocale?: string;

  constructor() {
    this.env = process.env.APP_STAGE || 'prod';
  }

  validateLocale(locale: string): string {
    const isValidLocaleFormat = /[a-z]{2}-([a-z0-9]{2,3})$/i.test(locale);

    return isValidLocaleFormat ? locale : Defaults.LOCALE;
  }

  private addLocaleToURL(locale: string) {
    const contentURL = GlobalNavContentURLs[this.env];
    return contentURL.replace(/{.*}/g, locale);
  }

  addQueryParamsToURL(url: string): string {
    const query = window.location.search;
    return query ? `${url}${query}` : url;
  }

  async fetchGlobalNavData(locale: string): Promise<ContentProps> {
    const contentURL = this.addQueryParamsToURL(this.addLocaleToURL(locale));
    const response = await fetch(contentURL);

    if (!response.ok) {
      throw new Error('Failed to fetch GN content');
    }

    const data = await response.json();

    return data;
  }

  private fetchSessionStorageData(): ContentProps | undefined {
    const key = this.buildStorageKey();
    const sessionData = sessionStorage.getItem(key);

    if (!sessionData) {
      return;
    }

    try {
      const parsedData = JSON.parse(sessionData);

      if (!parsedData) {
        throw new Error('Encountered an issue with SessionStorage content');
      }

      return parsedData;
    } catch (err) {
      sessionStorage.removeItem(key);
      console.log(err);
    }
  }

  async fetchContentData(locale: string): Promise<ContentProps> {
    this.currentLocale = locale;
    const cachedData = this.fetchSessionStorageData();

    if (cachedData) {
      return cachedData;
    }

    const contentData = await this.fetchGlobalNavData(locale);

    sessionStorage.setItem(this.buildStorageKey(), JSON.stringify(contentData));

    return contentData;
  }

  buildStorageKey(): string {
    const locale = this.currentLocale || Defaults.LOCALE;
    return `${SESSION_STORAGE_CONTENT_KEY}_${locale.toLowerCase()}`;
  }

  setExperimentSession(shortname: string, sessionId: string) {
    const localeData = this.fetchSessionStorageData();

    sessionStorage.setItem(
      this.buildStorageKey(),
      JSON.stringify({
        ...localeData,
        sessions: { ...(localeData?.sessions ?? {}), [shortname]: sessionId }
      })
    );
  }

  getExperimentSession(shortname: string): string | null {
    const localeData = this.fetchSessionStorageData();

    return localeData?.sessions?.[shortname] ?? null;
  }
}

export default new ContentStorage();
