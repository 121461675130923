import { useEffect, useState } from 'react';
import { SubMenuProps, PanelProps, SubMenu, Panel, Button } from '..';
import { useBreakpoint, useNavContext } from '../../hooks';
import { Breakpoint, Defaults } from '../../static/constants';
import { ContentType, ContentTypes, Keys, Testid } from '../../types';
import { classes, pixelsToNumber } from '../../utils';
import styles from './Menu.module.scss';
import { SvgRenderer } from '../SvgRenderer/SvgRenderer';
import { SvgEnum } from '../SvgRenderer/SvgRendererTypes';

export interface MenuProps extends ContentType {
  id?: string;
  label: string;
  content?: SubMenuProps | PanelProps;
  icon: {
    icon: SvgEnum;
  };
  mobileOnly?: boolean;
  url?: string;
  loginState?: boolean;
}

export const Menu = (props: MenuProps & Testid) => {
  const {
    menu,
    'upper-container': upperContainer,
    'menu-holder': menuHolder,
    'lower-container': lowerContainer,
    'accessibility-end': accessibilityEnd,
    'closing': closingClass,
    'visible': visibleClass
  } = styles;

  const upperContainerHeight = pixelsToNumber(styles['var-upper-container-height']);
  const drawerHeight = pixelsToNumber(styles['var-drawer-height']);

  const [closing, setClosing] = useState<boolean>(false);
  const { closeAllMenus, closeMenu, pressKey, data, gnHeight, activeMenus } = useNavContext();
  const [lowerContainerHeight, setLowerContainerHeight] = useState(0);
  const bp = useBreakpoint();
  const contentType = props.content?.contentType;
  const reversedActiveMenus = [...activeMenus].reverse();
  const showMenu =
    reversedActiveMenus.find((m) => m.contentType === ContentTypes.Menu)?.label === props.label;
  const showSubMenuItem =
    reversedActiveMenus.find((m) => m.contentType === ContentTypes.SubMenuItem)?.label ===
    props.label;
  const isVisibleMenu = showMenu || showSubMenuItem;

  const exitNavigation = () => {
    closeAllMenus();
    pressKey(Keys.End);
  };

  const handleBackButton = () => {
    setClosing(true);
    setTimeout(() => {
      closeMenu();
      setClosing(false);
    }, 250);
  };

  useEffect(() => {
    let height = 0;
    const drawer = props.content?.ctaDrawer;

    if (drawer && (bp === Breakpoint.SM || bp === Breakpoint.MD)) {
      const drawerTotalHeight =
        drawer.primaryUrl && drawer.secondaryUrl
          ? drawerHeight * 2
          : drawer.primaryUrl
          ? drawerHeight
          : 0;

      height = gnHeight - upperContainerHeight - drawerTotalHeight;
      setLowerContainerHeight(height);
    }
    setLowerContainerHeight(height);
  }, [gnHeight]);

  return (
    <div className={classes(menuHolder, { [visibleClass]: isVisibleMenu })}>
      <div
        className={classes(menu, { [closingClass]: closing })}
        data-testid={`${props.testid}-menu-container`}
        onMouseLeave={bp === Breakpoint.LG ? exitNavigation : undefined}
      >
        <div className={upperContainer}>
          <Button
            onClick={handleBackButton}
            testid={`${props.testid}-back-button`}
            ariaLabel={data.strings?.altTextLabels?.back || Defaults.LABELS.BACK}
          >
            <SvgRenderer name={SvgEnum.CHEVRON_LEFT} />
          </Button>
          {props.label}
        </div>
        <div className={lowerContainer} style={{ maxHeight: lowerContainerHeight || undefined }}>
          {(contentType === ContentTypes.SubMenu && (
            <SubMenu {...(props.content as SubMenuProps)} testid={props.testid} />
          )) ||
            (contentType === ContentTypes.Panel && (
              <Panel {...(props.content as PanelProps)} active testid={props.testid} />
            ))}
        </div>
        <button className={accessibilityEnd} onFocus={exitNavigation} />
      </div>
    </div>
  );
};
