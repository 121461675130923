import { SvgProps } from '../SvgRendererTypes';

export const SecurityCheckIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Security Check Icon</title>
      <path
        fill={fillColor}
        d="m12.11 20.91-.11.04a15.47 15.47 0 0 1-4.62-2.53 11.36 11.36 0 0 1-2.88-3.64V5.52A13.86 13.86 0 0 1 7.79 4c1.37-.461 2.78-.796 4.21-1 1.431.204 2.84.539 4.21 1 1.16.358 2.266.87 3.29 1.52v2.42c.351.207.686.44 1 .7V5a13.258 13.258 0 0 0-4-2A23.82 23.82 0 0 0 12 2a23.822 23.822 0 0 0-4.51 1 13.26 13.26 0 0 0-4 2v10a11.93 11.93 0 0 0 3.24 4.18A16.58 16.58 0 0 0 12 22a16.997 16.997 0 0 0 2.69-1.13 6.66 6.66 0 0 1-1.42-.42c-.481.21-.886.359-1.16.46Z"
      />
      <path
        fill={fillColor}
        d="M17.81 16.462c.06.026.125.039.19.038a.469.469 0 0 0 .35-.15.478.478 0 0 0 0-.7l-1.85-1.86V11a.5.5 0 1 0-1 0v3.21l2.15 2.14a.47.47 0 0 0 .16.112Z"
      />
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M16 8a6 6 0 1 0 0 12 6 6 0 0 0 0-12Zm0 1a5 5 0 1 1 0 10 5 5 0 0 1 0-10Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
