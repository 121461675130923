import { SvgProps } from '../SvgRendererTypes';

export const GuardServiceIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Guard Service Icon</title>
      <path
        fill={fillColor}
        d="m17.5 13 .34.06c.613.092 1.218.23 1.81.41.465.134.917.312 1.35.53v4.26a4.4 4.4 0 0 1-1.27 1.55A7.752 7.752 0 0 1 17.5 21a8.002 8.002 0 0 1-2.23-1.15 4.474 4.474 0 0 1-.52-.48l-.3-.32-.28-.5a2.373 2.373 0 0 1-.14-.26V14a8.175 8.175 0 0 1 1.35-.52c.592-.18 1.197-.318 1.81-.41l.31-.07ZM10 2 2 9v11h12c.194.216.409.414.64.59A9.08 9.08 0 0 0 17.5 22a9.08 9.08 0 0 0 2.84-1.41A5.1 5.1 0 0 0 22 18.45v-5a7.998 7.998 0 0 0-2.06-.89 15.773 15.773 0 0 0-1.94-.49V9l-1-.88V3h-4v1.62L10 2ZM3 19V9.45l7-6.12 4 3.5V4h2v4.58l1 .87v2.62a15.78 15.78 0 0 0-1.94.44 7.998 7.998 0 0 0-2.06.89v5c.075.19.166.374.27.55L3 19Z"
      />
    </svg>
  );
};
