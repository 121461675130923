import { SvgProps } from '../SvgRendererTypes';

export const PublicAgencyIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Public Agency Icon</title>
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.49 11H22L12 2L2 11H5.49V19H3V20H21V19H18.49V11ZM4.61 10L12 3.35L19.38 10H4.61ZM7.49 19H6.49V11H7.49V19ZM10.49 19H8.49V11H10.49V19ZM12.49 19H11.49V11H12.49V19ZM15.49 19H13.49V11H15.49V19ZM17.49 19H16.49V11H17.49V19Z"
      />
      <path d="M20.99 21H3V22H20.99V21Z" fill={fillColor} />
    </svg>
  );
};
