import { SvgProps } from '../SvgRendererTypes';

export const HomeSecuritySystemsIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill={fillColor}
        d="M7.07 11.37c0-.58.47-1.06 1.06-1.06.58 0 1.06.48 1.06 1.06 0 .58-.48 1.06-1.06 1.06-.58 0-1.06-.47-1.06-1.06ZM12.03 10.31a1.06 1.06 0 1 0 1.06 1.06c.01-.58-.47-1.06-1.06-1.06ZM14.88 11.37c0-.58.47-1.06 1.06-1.06.58 0 1.06.48 1.06 1.07a1.06 1.06 0 0 1-2.12-.01ZM8.13 13.69c-.59 0-1.06.48-1.06 1.06 0 .58.48 1.06 1.06 1.06.58 0 1.06-.48 1.06-1.06 0-.58-.48-1.06-1.06-1.06ZM10.97 14.75a1.06 1.06 0 1 1 1.06 1.06c-.58 0-1.06-.48-1.06-1.06ZM15.94 13.69c-.59 0-1.06.48-1.06 1.06 0 .58.48 1.06 1.06 1.06.58 0 1.06-.47 1.06-1.05 0-.58-.48-1.07-1.06-1.07ZM7.07 18.13c0-.58.47-1.06 1.06-1.06.58 0 1.06.48 1.06 1.06 0 .58-.48 1.06-1.06 1.06-.58 0-1.06-.47-1.06-1.06ZM12.03 17.07a1.06 1.06 0 1 0 1.06 1.06c.01-.58-.47-1.06-1.06-1.06ZM14.88 18.13c0-.58.47-1.06 1.06-1.06.58 0 1.06.48 1.06 1.07a1.06 1.06 0 0 1-2.12-.01Z"
      />
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="m12.72 2.31 7 7.37c.18.19.28.45.28.71v10.59c0 .56-.45 1.02-1 1.02H5c-.55 0-1-.46-1-1.02V10.36c0-.26.1-.52.28-.71l7-7.34c.2-.21.46-.31.72-.31.26 0 .52.1.72.31Zm-7.71 8.03L5 20.98 19 21V10.4l-7-7.39-6.99 7.33Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
