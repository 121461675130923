import { SvgProps } from '../SvgRendererTypes';

export const ShopAllProductsIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Shop All Products Icon</title>
      <path
        fill={fillColor}
        d="M19.22 8.59L19.19 8.57L22 7.11L14.97 3L12.03 4.7L9.09 3L2 7.15L4.73 8.59L2 10.04L5.83 12.28V17.98L11.99 21L18.54 17.92V12.1L22 10.07L19.19 8.61L19.22 8.59ZM14.97 4.16L19.92 7.06L18.11 8L13.04 5.32L13.05 5.3L13.03 5.29L14.97 4.16ZM17.07 8.59L12 11.26L6.92 8.59L12 5.91L17.07 8.59ZM4.07 7.1L9.09 4.16L11.03 5.28L5.83 8.03L4.07 7.1ZM4.06 10.08L5.83 9.15L11.01 11.87L11 11.89L11.02 11.9L9.08 13.02L4.06 10.08ZM17.53 17.29L12 19.88L6.84 17.35V12.87L9.09 14.18L12.03 12.48L14.97 14.18L17.53 12.68V17.29V17.29ZM19.92 10.12L14.97 13.02L13.03 11.9L13.05 11.89L13.03 11.86L18.1 9.19L19.92 10.12Z"
      />
    </svg>
  );
};
