import { SvgProps } from '../SvgRendererTypes';

export const CameraBackupIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Camera Backup Icon</title>
      <path
        fill={fillColor}
        d="M21.41 8.49a9.134 9.134 0 0 0-5-5.17 9.093 9.093 0 0 0-3.6-.7 9.303 9.303 0 0 0-9.28 9.18l-.71-.5a.508.508 0 0 0-.18-.09.452.452 0 0 0-.2-.01c-.07.01-.13.03-.19.07a.57.57 0 0 0-.14.13c-.04.05-.07.11-.09.18-.02.06-.02.13-.01.2s.03.13.07.19c.04.06.08.11.14.14l1.82 1.21 1.82-1.21c.05-.04.1-.09.13-.15a.62.62 0 0 0 .07-.19c.01-.07.01-.13-.01-.2-.02-.06-.05-.12-.08-.18a.47.47 0 0 0-.15-.13.619.619 0 0 0-.19-.07.452.452 0 0 0-.2.01c-.06.02-.12.05-.18.09l-.71.5c.03-1.64.54-3.24 1.48-4.59a8.353 8.353 0 0 1 3.77-3c1.52-.61 3.2-.75 4.8-.4 1.61.34 3.07 1.15 4.22 2.33a8.275 8.275 0 0 1 2.22 4.28 8.32 8.32 0 0 1-3.61 8.49c-1.37.9-2.98 1.38-4.62 1.37a8.59 8.59 0 0 1-3.53-.73 8.72 8.72 0 0 1-2.93-2.1.419.419 0 0 0-.16-.11.99.99 0 0 0-.18-.02c-.06 0-.13.01-.19.04s-.12.06-.16.11a.59.59 0 0 0-.1.71 9.198 9.198 0 0 0 3.23 2.4c1.24.56 2.58.84 3.94.83 1.22 0 2.43-.24 3.55-.7a9.296 9.296 0 0 0 3.01-2.01 9.18 9.18 0 0 0 2.01-3.02c.47-1.13.7-2.34.7-3.56.05-1.24-.15-2.47-.58-3.62Z"
      />
      <path fill={fillColor} d="M15.34 13.58a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" />
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M16.84 8.45a.923.923 0 0 0-.28-.19.784.784 0 0 0-.33-.07H8.16a.88.88 0 0 0-.61.25c-.15.17-.24.39-.24.62v5.89c0 .23.09.45.25.62.16.16.38.25.61.25h8.07c.11 0 .22-.02.33-.07a.87.87 0 0 0 .53-.81v-1.18l1.57.94a.42.42 0 0 0 .61-.19c.02-.05.03-.1.03-.16V9.51a.42.42 0 0 0-.25-.39.428.428 0 0 0-.38.03l-1.57.94V9.07c-.02-.23-.11-.46-.27-.62Zm-.73 6.38H8.26V9.18h7.85v5.65Zm2.2-4.33v2.85l-1.22-.73v-1.38l1.22-.74Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
