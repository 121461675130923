import { SvgProps } from '../SvgRendererTypes';

export const OffersIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Offers Icon</title>
      <path
        fill={fillColor}
        d="M21.49 4.78951C21.47 4.75951 19.76 1.60951 14.84 3.74951C11.68 5.12951 8.49 3.10951 8.45 3.08951C8.22 2.93951 7.92 2.99951 7.77 3.22951C7.62 3.45951 7.68 3.76951 7.91 3.91951C8.06 4.01951 11.58 6.25951 15.24 4.65951C19.27 2.89951 20.55 5.14951 20.61 5.26951C20.62 5.27951 21.34 6.51951 20.74 7.79951C20.5 8.31951 20.07 8.77951 19.47 9.17951L19.59 8.63951L13.31 6.22951L2 11.3995L6.26 20.9995L18.07 15.6095L19.19 10.4895C20.39 9.89951 21.23 9.14951 21.66 8.22951C22.47 6.46951 21.52 4.82951 21.49 4.78951ZM17.16 14.9195L6.77 19.6595L3.33 11.8995L13.31 7.33951L18.45 9.22951L18.33 9.77951C17.71 10.0395 17.01 10.2695 16.19 10.4495C15.95 10.0395 15.52 9.75951 15.01 9.75951C14.25 9.75951 13.64 10.3895 13.64 11.1695C13.64 11.9495 14.25 12.5795 15.01 12.5795C15.68 12.5795 16.23 12.0895 16.36 11.4495C16.98 11.3095 17.54 11.1495 18.07 10.9695L17.16 14.9195Z"
      />
    </svg>
  );
};
