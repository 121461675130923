import { SvgProps } from '../SvgRendererTypes';

export const CommunityCommitmentsIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Community Commitments Icon</title>
      <path
        fill={fillColor}
        d="m18.55 5-.41.24c-.01.01-.96.51-2.12.26-.27-.07-2.6-.66-3.93.21-1.73-1.01-3.56-.21-3.57-.2-1.11.31-2.35-.3-2.36-.31L5.78 5 2 10.26l2.87 2.24c-.41.62-.34 1.49.2 2 .26.25.6.39.95.39h.03c.03.37.18.73.46.99.26.25.6.39.95.39h.01c.04.36.18.71.45.97.26.25.6.39.95.39h.03c.03.37.18.73.46.99.26.24.6.38.95.38h.1c.29-.02.57-.14.8-.33.07.01.15.02.25.02.22 0 .48-.04.72-.2.21-.14.45-.42.54-.92.07.01.13.01.2.01.23 0 .48-.04.72-.15.36-.16.63-.45.81-.86.63-.01 1.64-.35 1.9-1.62.39-.04.9-.17 1.3-.58.24-.24.38-.55.47-.9L22 10l-3.45-5ZM6.32 13.73c-.07.09-.17.15-.28.16a.339.339 0 0 1-.28-.11c-.2-.19-.22-.55-.04-.77l1.24-1.58c.07-.09.17-.15.28-.16h.02c.07 0 .17.02.26.11.2.19.22.55.04.77l-1.24 1.58Zm1.44 1.38c-.07.09-.17.15-.28.16a.4.4 0 0 1-.28-.11c-.2-.19-.22-.55-.04-.77L8.4 12.8c.07-.09.17-.15.28-.16.08 0 .18.01.28.11.2.19.22.55.04.77l-1.24 1.59Zm1.42 1.35c-.08.1-.18.15-.28.16a.339.339 0 0 1-.28-.11c-.2-.19-.22-.55-.04-.77l1.24-1.58c.07-.09.17-.15.28-.16h.02c.09 0 .19.04.26.11.2.19.22.55.04.77l-1.24 1.58Zm2.68-.2-1.24 1.58c-.07.09-.17.15-.28.16-.07 0-.18-.01-.28-.11-.2-.19-.22-.55-.04-.77l1.24-1.58c.07-.09.17-.15.28-.16h.02c.07 0 .17.02.26.11.19.19.21.55.04.77Zm5.07-2.59c-.26.26-.67.29-.85.29h-.02c-.18 0-.35.07-.47.2-.2.2-.2.47-.19.47-.1.95-.88.94-1.02.93a.81.81 0 0 0-.83.57c-.09.26-.22.34-.31.38-.13.06-.29.07-.44.05.28-.6.18-1.34-.3-1.8-.27-.26-.62-.39-.98-.38-.03-.37-.18-.73-.46-1-.27-.25-.61-.38-.96-.38-.04-.36-.18-.72-.45-.97-.27-.26-.62-.39-.98-.38-.03-.37-.18-.73-.46-1a1.33 1.33 0 0 0-1.04-.38c-.39.03-.75.22-1 .54l-.7.89-2.1-1.63 2.73-3.8c.57.21 1.64.5 2.75.19.01-.01 1.25-.53 2.44-.01L9.08 8.53l-.09.12c-.21.37-.41 1.24.35 1.98.55.39 1.38.64 2.22-.03l1.86-1.63 3.75 3.99c-.02.3-.08.55-.24.71Zm.99-1.37-4.43-4.72-2.57 2.26c-.51.44-.93.03-.94.03-.26-.26-.2-.65-.05-.76l2.74-2.59c.75-.51 2.49-.2 3.12-.03 1.03.22 1.94-.07 2.44-.25l2.53 3.62-2.84 2.44Z"
      />
    </svg>
  );
};
