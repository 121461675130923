import { SvgProps } from '../SvgRendererTypes';

export const CameraIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Camera Icon</title>
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.47 7.38C21.47 7.38 21.467 7.38 21.465 7.38C21.371 7.38 21.283 7.406 21.208 7.451L21.21 7.45L18 9.39V7C18 6.448 17.552 6 17 6H3C2.448 6 2 6.448 2 7V17C2 17.552 2.448 18 3 18H17C17.552 18 18 17.552 18 17V14.6L21.21 16.54C21.283 16.584 21.371 16.61 21.465 16.61C21.467 16.61 21.468 16.61 21.47 16.61C21.746 16.61 21.97 16.386 21.97 16.11V7.88C21.97 7.604 21.746 7.38 21.47 7.38ZM17 17H3V7H17V17ZM21 15.23L18 13.44V10.56L21 8.77V15.23ZM15 14C14.448 14 14 14.448 14 15C14 15.552 14.448 16 15 16C15.552 16 16 15.552 16 15C16 14.448 15.552 14 15 14Z"
      />
    </svg>
  );
};
