import { SvgProps } from '../SvgRendererTypes';

export const LogInIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Log In Icon</title>
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 11.931C2 6.44612 6.44612 2 11.931 2C17.4159 2 21.8621 6.44612 21.8621 11.931C21.8621 17.4159 17.4159 21.8621 11.931 21.8621C6.44612 21.8621 2 17.4159 2 11.931ZM11.931 11.4345C10.0114 11.4345 8.45517 9.87829 8.45517 7.95862C8.45517 6.03895 10.0114 4.48276 11.931 4.48276C13.8507 4.48276 15.4069 6.03895 15.4069 7.95862C15.4069 9.87829 13.8507 11.4345 11.931 11.4345ZM11.931 5.47586C10.5596 5.47586 9.44827 6.58714 9.44827 7.95862C9.44827 9.3301 10.5596 10.4414 11.931 10.4414C13.3025 10.4414 14.4138 9.3301 14.4138 7.95862C14.4138 6.58714 13.3025 5.47586 11.931 5.47586ZM11.931 2.9931C16.8618 3.00105 20.856 6.99928 20.856 11.931C20.856 13.7822 20.293 15.5012 19.3296 16.9283C17.7218 14.4992 15.0087 12.944 11.931 12.944C8.8534 12.944 6.14025 14.4992 4.53241 16.8658C3.5691 15.5022 3.00601 13.7822 3.00601 11.931C3.00601 6.99928 7.00127 3.00105 11.931 2.9931ZM5.168 17.7526C6.80265 19.6554 9.22582 20.863 11.931 20.869C14.6402 20.866 17.0664 19.6584 18.6941 17.7069C17.2938 15.4466 14.7912 13.9411 11.936 13.9411C9.08083 13.9411 6.57821 15.4466 5.168 17.7526Z"
      />
    </svg>
  );
};
