import { SvgProps } from '../SvgRendererTypes';

export const BusinessInstallationIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Business Installation</title>
      <path
        fill={fillColor}
        d="M11.5 7.99C9.29998 7.99 7.52998 9.77 7.52998 11.96C7.52998 14.15 9.30998 15.93 11.5 15.93C13.69 15.93 15.47 14.15 15.47 11.96C15.48 9.77 13.7 7.99 11.5 7.99ZM11.5 14.94C9.84998 14.94 8.51998 13.61 8.51998 11.96C8.51998 10.31 9.84998 8.98 11.5 8.98C13.15 8.98 14.48 10.31 14.48 11.96C14.48 13.61 13.15 14.94 11.5 14.94Z"
      />
      <path
        fill={fillColor}
        d="M20.36 10.77V4.55L20.71 3.99L20.23 2H19.49L19 4L19.36 4.56V10.78C18.51 10.86 17.83 11.58 17.83 12.45V20.31C17.83 21.24 18.59 21.99 19.51 21.99H20.3C21.23 21.99 21.98 21.23 21.98 20.31V12.45C22 11.54 21.27 10.8 20.36 10.77ZM21 20.32C21 20.69 20.69 21 20.32 21H19.53C19.15 21 18.85 20.69 18.85 20.32V19.56H21V20.32ZM21 18.55H18.84V12.45C18.84 12.07 19.15 11.77 19.52 11.77H20.31C20.68 11.77 20.99 12.08 20.99 12.45L21 18.55Z"
      />
      <path
        fill={fillColor}
        d="M13.45 18.19L13.17 19.31L12.8 20.76C12.78 20.89 12.64 21 12.5 21H10.51C10.37 21 10.24 20.89 10.2 20.76L9.84998 19.31L9.56998 18.19C8.30998 17.92 7.13998 16.78 7.13998 16.78L6.02998 17.09L4.59998 17.5C4.46998 17.54 4.30998 17.47 4.23998 17.35L3.24998 15.62C3.17998 15.5 3.20998 15.33 3.30998 15.23L4.37998 14.2L5.19998 13.4C4.94998 12.27 5.19998 10.61 5.19998 10.61L4.38998 9.8L3.31998 8.76C3.21998 8.66 3.19998 8.49 3.26998 8.37L4.25998 6.64C4.32998 6.51 4.49998 6.45 4.62998 6.49L6.04998 6.9L7.14998 7.21C7.14998 7.21 8.13998 6.38 9.55998 5.81L9.83998 4.7L10.21 3.25C10.24 3.11 10.38 3 10.51 3H12.5C12.64 3 12.78 3.11 12.81 3.24L13.16 4.69L13.44 5.81C13.44 5.81 15.19 6.48 15.86 7.21L16.96 6.89L18.36 6.49V5.49L16.14 6.13C15.86 5.9 15.56 5.69 15.24 5.51C14.92 5.32 14.59 5.17 14.25 5.04L13.56 2.25C13.52 2.11 13.38 2 13.25 2H9.76998C9.62998 2 9.48998 2.11 9.45998 2.24L8.76998 5.03C8.42998 5.16 8.09998 5.32 7.77998 5.5C7.45998 5.69 7.15998 5.9 6.87998 6.12L4.13998 5.34C4.00998 5.3 3.83998 5.37 3.77998 5.48L2.03998 8.52C1.96998 8.63 1.98998 8.81 2.08998 8.91L4.13998 10.91C4.08998 11.27 4.05998 11.63 4.05998 12.01C4.05998 12.38 4.08998 12.75 4.13998 13.11L2.08998 15.11C1.98998 15.21 1.96998 15.38 2.03998 15.5L3.77998 18.53C3.84998 18.65 4.00998 18.72 4.13998 18.68L6.87998 17.89C7.15998 18.12 7.45998 18.33 7.77998 18.51C8.09998 18.7 8.42998 18.85 8.76998 18.98L9.45998 21.77C9.48998 21.89 9.62998 22 9.76998 22H13.25C13.39 22 13.53 21.89 13.56 21.76L14.25 18.97C14.58 18.84 14.91 18.68 15.24 18.5C15.56 18.31 15.86 18.1 16.14 17.88L16.85 18.08V17.06L15.87 16.78C14.96 17.64 13.45 18.19 13.45 18.19Z"
      />
    </svg>
  );
};
