import { useBreakpoint, useNavContext } from '../../hooks';
import { Breakpoint, Defaults, FallbackLinks } from '../../static/constants';
import { Button } from '../common/Button';
import { CtaDrawer } from '../CtaDrawer/CtaDrawer';
import { appendQueryParamsToUrl, classes, generateKey } from '../../utils';
import { SvgRenderer } from '../SvgRenderer/SvgRenderer';
import { SvgEnum } from '../SvgRenderer/SvgRendererTypes';
import { MenuProps } from '../Menu/Menu';
import { LogoToggle } from '../LogoToggle/LogoToggle';

import styles from './MobileNavDropdown.module.scss';

interface MobileNavDropdownProps {
  loginStatus: boolean;
}

const MobileMenuItem = ({
  item,
  onMenuClick
}: {
  item: MenuProps;
  onMenuClick: (menu: MenuProps) => void;
}) => {
  return (
    <li>
      <Button
        onClick={() => onMenuClick(item)}
        ariaLabel={item.label}
        testid={`menu-btn-mobile-${item.id}`}
      >
        <SvgRenderer name={item.icon.icon} />
        {item.label}
        {item.content && <SvgRenderer name={SvgEnum.CHEVRON_RIGHT} />}
      </Button>
    </li>
  );
};

export const MobileNavDropdown = (props: MobileNavDropdownProps) => {
  const {
    'mobile-nav-dropdown': mobileNavDropdown,
    'mobile-nav-dropdown-container': mobileNavDropdownContainer,
    visible
  } = styles;
  const { data, expanded, openMenu, header } = useNavContext();
  const bp = useBreakpoint();

  const primaryCTA = data.strings?.user?.status?.primaryCTA || {
    url: FallbackLinks.SHOP_ALL,
    label: Defaults.LABELS.SHOP_ALL
  };
  primaryCTA.url = header.returnTo
    ? appendQueryParamsToUrl(primaryCTA.url || '', { return: header.returnTo })
    : primaryCTA.url;
  const {
    url: primaryCTAUrl = FallbackLinks.SHOP_ALL,
    label: primaryCTALabel = Defaults.LABELS.SHOP_ALL
  } = primaryCTA;

  const { url: secondaryCTAUrl, label: secondaryCTALabel } = data.strings?.user?.status
    ?.secondaryCTA || {
    url: FallbackLinks.SHOP_DEALS,
    label: Defaults.LABELS.SHOP_DEALS
  };

  const onMenuClick = (menu: MenuProps) => {
    if (menu.content) {
      openMenu(menu);
    } else if (menu.url) {
      window.location.assign(menu.url);
    }
  };

  const items: [MenuProps[], MenuProps[]] = data.menus.reduce(
    (acc, item, index) => {
      const currentIndex = item.content ? 0 : 1;
      (acc[currentIndex] as MenuProps[]) = [
        ...acc[currentIndex],
        { id: index, ...item }
      ] as MenuProps[];
      return acc;
    },
    [[], []]
  );

  const [topItems, bottomItems] = items;

  const filterItems = (item: MenuProps) =>
    !((item.loginState && !props.loginStatus) || (item.loginState === false && props.loginStatus));

  const filteredTopItems = topItems.filter(filterItems);

  const filteredBottomItems = bottomItems.filter(filterItems);

  return (
    <div
      className={classes(mobileNavDropdownContainer, {
        [visible]: expanded && (bp === Breakpoint.SM || bp === Breakpoint.MD)
      })}
    >
      <div className={mobileNavDropdown}>
        {bp === Breakpoint.MD && (
          <LogoToggle
            showToggle={data.menus.length > 0}
            strings={{
              menu: data.strings?.altTextLabels?.menu || Defaults.LABELS.MENU,
              close: data.strings?.altTextLabels?.close || Defaults.LABELS.CLOSE,
              ring: data.strings?.altTextLabels?.ring || Defaults.LABELS.RING
            }}
          />
        )}
        {filteredTopItems.length > 0 && (
          <ul>
            {filteredTopItems.map((item) => (
              <MobileMenuItem key={generateKey(item.label)} {...{ item, onMenuClick }} />
            ))}
          </ul>
        )}
        {filteredBottomItems.length > 0 && (
          <ul>
            {filteredBottomItems.map((item, index) => (
              <MobileMenuItem key={generateKey(item.label)} {...{ item, index, onMenuClick }} />
            ))}
          </ul>
        )}
      </div>
      {header.shop && (
        <div>
          <CtaDrawer
            primaryCopy={primaryCTALabel}
            primaryUrl={primaryCTAUrl}
            secondaryCopy={secondaryCTALabel}
            secondaryUrl={secondaryCTAUrl}
            testid="menu"
          />
        </div>
      )}
    </div>
  );
};
