import { SvgProps } from '../SvgRendererTypes';

export const RingForBusiness = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Ring for Business Icon</title>
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 17.9302H5.1V10.7202H13V17.9302ZM12 11.7102H6.1V16.9202H12V11.7102Z"
      />
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3H21V21H3V3ZM20 6.08V4H16.8V6.08C16.8 6.95 17.52 7.65 18.4 7.65C19.28 7.65 20 6.95 20 6.08ZM7.29 4H4V6.09C4 6.95 4.74 7.66 5.65 7.66C6.55 7.66 7.28 6.96 7.29 6.09V4ZM8.29 6.1C8.3 6.96 9.02 7.66 9.91 7.65C10.8 7.65 11.53 6.95 11.53 6.08V4H8.29V6.1ZM12.56 4V4.51H12.57V6.08C12.57 6.95 13.29 7.65 14.17 7.65C15.05 7.65 15.77 6.95 15.77 6.08V4H12.56ZM15.12 11.71V20H17.84V16.35H16.76C16.48 16.35 16.26 16.13 16.26 15.85C16.26 15.57 16.48 15.35 16.76 15.35H17.84V11.71H15.12ZM18.84 20H20.01V8.1C19.69 8.34 19.33 8.53 18.93 8.61C18.76 8.64 18.59 8.66 18.41 8.66C18.05 8.66 17.71 8.59 17.4 8.46C17.25 8.39 17.1 8.31 16.96 8.22C16.7 8.05 16.47 7.83 16.29 7.57C16.21 7.69 16.12 7.81 16.01 7.91C15.89 8.02 15.76 8.13 15.62 8.22C15.21 8.5 14.71 8.66 14.17 8.66C13.3 8.66 12.52 8.23 12.05 7.57C11.76 7.96 11.38 8.27 10.93 8.46C10.61 8.59 10.27 8.66 9.91 8.66C9.37 8.66 8.87 8.5 8.45 8.22C8.32 8.13 8.2 8.04 8.09 7.93C7.98 7.82 7.88 7.7 7.79 7.58C7.65 7.77 7.49 7.94 7.3 8.09C7.11 8.24 6.9 8.36 6.68 8.46C6.52 8.52 6.35 8.58 6.18 8.61C6.01 8.64 5.83 8.66 5.65 8.66C5.02 8.66 4.45 8.43 4 8.08V20H14.12V10.71H18.84V20Z"
      />
    </svg>
  );
};
