import { ContentType, Keys, Testid } from '../../types';
import { Link } from '../common/Link';
import { SvgRenderer } from '../SvgRenderer/SvgRenderer';
import { SvgEnum } from '../SvgRenderer/SvgRendererTypes';
import styles from './Title.module.scss';

export interface TitleProps extends ContentType {
  label?: string;
  url: string;
  urlLabel: string;
}

export const Title = ({ label = '', url, urlLabel, testid }: TitleProps & Testid) => {
  const { title: titleContainer } = styles;

  function handleClick(url: string) {
    window.location.assign(url);
  }

  function handleKeyUp(e: React.KeyboardEvent<HTMLElement>, url: string) {
    e.preventDefault();
    if (e.key === Keys.Enter) {
      window.location.assign(url);
    }
  }

  return (
    <div
      className={titleContainer}
      onClick={() => handleClick(url)}
      onKeyUp={(e) => handleKeyUp(e, url)}
      role="button"
      tabIndex={-1}
      data-testid={`${testid}-panel-title`}
    >
      {label && <span>{label}</span>}
      <Link href={url}>
        {urlLabel}
        <SvgRenderer name={SvgEnum.CHEVRON_RIGHT} />
      </Link>
    </div>
  );
};
