import { SvgProps } from '../SvgRendererTypes';

export const StarkIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Stark Icon</title>
      <path
        fill={fillColor}
        d="m12 8 1.4 4.1 1.1.1L12 4.8l-2.5 7.5 1.1-.2L12 8ZM5.5 11.5l1.6-3.6 1.5 4.6c-.1 0-.8.2-1 .3l-.7-2.1-.8 1.8H4.2c-.1-.5 0-1 0-1h1.3ZM15.4 12.4l-.9-.2.5 1.6.4-1.4ZM9.5 12.3l-.9.2.4 1.2.5-1.4ZM19.8 12.5h-1.9l-.8-1.8-.7 2-1-.3L17 7.9l1.5 3.6h1.3s.1.5 0 1Z"
      />
      <path
        fill={fillColor}
        d="M13.4 12.1c-1.4-.1-2.8 0-2.8 0L9 16.9l-1.4-4.1c-1.9.5-3 1.2-3 1.2.9 3.3 3.9 5.7 7.4 5.7 3.6 0 6.6-2.4 7.4-5.7-1-.6-2-1-3-1.3l-1.5 4.2-1.5-4.8Z"
      />
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M2 12C2 6.5 6.5 2 12 2s10 4.5 10 10-4.5 10-10 10S2 17.5 2 12Zm1 0c0 5 4 9 9 9s9-4 9-9-4-9-9-9-9 4-9 9Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
