import { SvgProps } from '../SvgRendererTypes';

export const BusinessSolutionsIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Business Solutions</title>
      <path
        fill={fillColor}
        d="M13.17,16.01h-2.27c-2.93,0-5.32,2.37-5.32,5.28V22h0.98v-0.71c0-2.08,1.5-3.81,3.48-4.2l1.47,1.68V22h1v-3.24l1.34-1.68
		c2.06,0.33,3.65,2.09,3.65,4.22V22h0.98v-0.71C18.48,18.38,16.1,16.01,13.17,16.01z M12,17.83L11.28,17h1.38L12,17.83z"
      />
      <path
        fill={fillColor}
        d="M14.8,12.27v0.66h0.74c0.58-0.75,0.94-1.68,0.94-2.71V8.29c0-2.46-2-4.46-4.46-4.46c-2.46,0-4.46,2-4.46,4.46v1.93
		c0,1.39,0.65,2.61,1.65,3.43v-1.43c-0.4-0.57-0.65-1.26-0.65-2V8.29c0-1.91,1.55-3.47,3.46-3.47c1.91,0,3.47,1.56,3.47,3.47v1.93
		C15.48,10.99,15.22,11.69,14.8,12.27z"
      />
      <path
        fill={fillColor}
        d="M18.63,7.36h-0.45C18.09,4.39,15.36,2,12,2C8.65,2,5.92,4.39,5.83,7.36H5.37C4.55,7.36,4,8.03,4,8.85v2.86
		c0,0.51,0.42,0.93,0.93,0.93h1.89V7.5C6.82,5.02,9.15,3,12,3c2.86,0,5.18,2.02,5.18,4.5l-0.01,0l0.01,5.14v1.45h-3.37v-0.93
		c0-0.28-0.22-0.5-0.5-0.5h-2.62c-0.28,0-0.5,0.22-0.5,0.5v1.48c0,0.28,0.22,0.5,0.5,0.5h2.62h4.37c0.28,0,0.5-0.22,0.5-0.5v-2h0.89
		c0.51,0,0.93-0.42,0.93-0.93V8.85C20,8.03,19.45,7.36,18.63,7.36z M5.82,11.64H5V8.85c0-0.28,0.1-0.5,0.38-0.5h0.45V11.64z
		 M12.82,14.09h-1.62v-0.38h1.62V14.09z M19,11.64h-0.82l-0.01-3.29v0h0.45c0.27,0,0.38,0.22,0.38,0.5V11.64z"
      />
    </svg>
  );
};
