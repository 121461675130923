import { SvgProps } from '../SvgRendererTypes';

export const NeighborhoodWatchIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Neighborhood Watch Icon</title>
      <path
        fill={fillColor}
        d="M12 3C13.4309 3.20393 14.8402 3.53866 16.21 4C17.3693 4.35829 18.4757 4.86946 19.5 5.52V14.79C18.7955 16.1824 17.8157 17.4174 16.62 18.42C15.2396 19.539 13.6767 20.4117 12 21C10.3267 20.427 8.76404 19.5712 7.37999 18.47C6.17424 17.4533 5.19342 16.1966 4.49999 14.78V5.52C5.5254 4.87152 6.63152 4.36048 7.78999 4C9.1598 3.53866 10.569 3.20393 12 3ZM12 2C10.4678 2.18643 8.95737 2.52134 7.48999 3C6.05304 3.43539 4.70047 4.11168 3.48999 5V15C4.24366 16.6182 5.35086 18.0466 6.72999 19.18C8.30132 20.4315 10.087 21.387 12 22C13.9095 21.3859 15.6917 20.4304 17.26 19.18C18.6391 18.0466 19.7463 16.6182 20.5 15V5C19.2895 4.11168 17.9369 3.43539 16.5 3C15.0358 2.52197 13.5288 2.18706 12 2Z"
      />
      <path
        fill={fillColor}
        d="M12 9C12.3956 9 12.7822 8.8827 13.1111 8.66294C13.44 8.44318 13.6964 8.13082 13.8477 7.76537C13.9991 7.39992 14.0387 6.99778 13.9616 6.60982C13.8844 6.22186 13.6939 5.86549 13.4142 5.58579C13.1345 5.30608 12.7781 5.1156 12.3902 5.03843C12.0022 4.96126 11.6001 5.00087 11.2346 5.15224C10.8692 5.30362 10.5568 5.55996 10.3371 5.88886C10.1173 6.21776 9.99999 6.60444 9.99999 7C9.99999 7.53043 10.2107 8.03914 10.5858 8.41421C10.9609 8.78929 11.4696 9 12 9ZM12 6C12.1978 6 12.3911 6.05865 12.5556 6.16853C12.72 6.27841 12.8482 6.43459 12.9239 6.61732C12.9996 6.80004 13.0194 7.00111 12.9808 7.19509C12.9422 7.38907 12.8469 7.56725 12.7071 7.70711C12.5672 7.84696 12.3891 7.9422 12.1951 7.98079C12.0011 8.01937 11.8 7.99957 11.6173 7.92388C11.4346 7.84819 11.2784 7.72002 11.1685 7.55557C11.0586 7.39112 11 7.19778 11 7C11 6.73478 11.1053 6.48043 11.2929 6.29289C11.4804 6.10536 11.7348 6 12 6Z"
      />
      <path
        fill={fillColor}
        d="M7.99999 10.5C8.39555 10.5 8.78223 10.3827 9.11113 10.1629C9.44003 9.94318 9.69637 9.63082 9.84775 9.26537C9.99912 8.89991 10.0387 8.49778 9.96156 8.10982C9.88439 7.72186 9.69391 7.36549 9.4142 7.08579C9.1345 6.80608 8.77813 6.6156 8.39017 6.53843C8.00221 6.46126 7.60008 6.50087 7.23462 6.65224C6.86917 6.80362 6.55681 7.05996 6.33705 7.38886C6.11729 7.71776 5.99999 8.10444 5.99999 8.5C5.99999 9.03043 6.2107 9.53914 6.58578 9.91421C6.96085 10.2893 7.46956 10.5 7.99999 10.5ZM7.99999 7.5C8.19777 7.5 8.39111 7.55865 8.55556 7.66853C8.72001 7.77841 8.84818 7.93459 8.92387 8.11732C8.99956 8.30004 9.01936 8.50111 8.98078 8.69509C8.94219 8.88907 8.84695 9.06725 8.7071 9.20711C8.56724 9.34696 8.38906 9.4422 8.19508 9.48079C8.0011 9.51937 7.80003 9.49957 7.61731 9.42388C7.43458 9.34819 7.2784 9.22002 7.16852 9.05557C7.05864 8.89112 6.99999 8.69778 6.99999 8.5C6.99999 8.23478 7.10535 7.98043 7.29288 7.79289C7.48042 7.60536 7.73477 7.5 7.99999 7.5Z"
      />
      <path
        fill={fillColor}
        d="M16 10.5C16.3956 10.5 16.7822 10.3827 17.1111 10.1629C17.44 9.94318 17.6964 9.63082 17.8477 9.26537C17.9991 8.89991 18.0387 8.49778 17.9616 8.10982C17.8844 7.72186 17.6939 7.36549 17.4142 7.08579C17.1345 6.80608 16.7781 6.6156 16.3902 6.53843C16.0022 6.46126 15.6001 6.50087 15.2346 6.65224C14.8692 6.80362 14.5568 7.05996 14.3371 7.38886C14.1173 7.71776 14 8.10444 14 8.5C14 9.03043 14.2107 9.53914 14.5858 9.91421C14.9609 10.2893 15.4696 10.5 16 10.5ZM16 7.5C16.1978 7.5 16.3911 7.55865 16.5556 7.66853C16.72 7.77841 16.8482 7.93459 16.9239 8.11732C16.9996 8.30004 17.0194 8.50111 16.9808 8.69509C16.9422 8.88907 16.847 9.06725 16.7071 9.20711C16.5672 9.34696 16.3891 9.4422 16.1951 9.48079C16.0011 9.51937 15.8 9.49957 15.6173 9.42388C15.4346 9.34819 15.2784 9.22002 15.1685 9.05557C15.0586 8.89112 15 8.69778 15 8.5C15 8.23478 15.1053 7.98043 15.2929 7.79289C15.4804 7.60536 15.7348 7.5 16 7.5Z"
      />
      <path
        fill={fillColor}
        d="M16 11H15.74C15.2732 11.0529 14.8319 11.2406 14.47 11.54C14.386 10.9535 14.0864 10.4195 13.6296 10.0422C13.1728 9.66496 12.5917 9.47162 12 9.5H11.74C11.2007 9.5372 10.6895 9.75464 10.2887 10.1174C9.8879 10.4801 9.62068 10.9671 9.52999 11.5C9.08977 11.1679 8.5514 10.9919 7.99999 11H7.73999C7.10759 11.0882 6.52976 11.406 6.11666 11.8929C5.70355 12.3798 5.48404 13.0017 5.49999 13.64V15C5.49999 15.1326 5.55267 15.2598 5.64644 15.3536C5.74021 15.4473 5.86738 15.5 5.99999 15.5H9.49999V16.56C9.50381 16.6218 9.51978 16.6822 9.547 16.7377C9.57422 16.7933 9.61214 16.843 9.6586 16.8839C9.70506 16.9247 9.75913 16.956 9.81771 16.976C9.8763 16.9959 9.93824 17.0041 9.99999 17H14C14.0617 17.0041 14.1237 16.9959 14.1823 16.976C14.2409 16.956 14.2949 16.9247 14.3414 16.8839C14.3878 16.843 14.4258 16.7933 14.453 16.7377C14.4802 16.6822 14.4962 16.6218 14.5 16.56V15.5H18C18.1326 15.5 18.2598 15.4473 18.3535 15.3536C18.4473 15.2598 18.5 15.1326 18.5 15V13.5C18.5 12.837 18.2366 12.2011 17.7678 11.7322C17.2989 11.2634 16.663 11 16 11ZM6.49999 14.5V13.64C6.48291 13.2498 6.61006 12.8669 6.85721 12.5644C7.10435 12.262 7.45419 12.061 7.83999 12H7.99999C8.39782 12 8.77935 12.158 9.06065 12.4393C9.34196 12.7206 9.49999 13.1022 9.49999 13.5V14.5H6.49999ZM13.5 16H10.5V11.83C10.5194 11.4845 10.666 11.1584 10.9116 10.9147C11.1572 10.6709 11.4844 10.5268 11.83 10.51H12C12.3585 10.4761 12.716 10.5837 12.9962 10.8098C13.2764 11.0359 13.4572 11.3625 13.5 11.72V16ZM17.5 14.5H14.5V13.64C14.4832 13.2483 14.6118 12.8642 14.861 12.5615C15.1101 12.2588 15.4624 12.0588 15.85 12H16C16.3978 12 16.7793 12.158 17.0606 12.4393C17.342 12.7206 17.5 13.1022 17.5 13.5V14.5Z"
      />
    </svg>
  );
};
