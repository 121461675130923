import { useBreakpoint, useNavContext } from '../../hooks';
import { Breakpoint } from '../../static/constants';
import { ContentType, Keys, LinkProps, Testid } from '../../types';
import { classes, generateKey } from '../../utils';
import { SvgRenderer } from '../SvgRenderer/SvgRenderer';
import { SvgEnum } from '../SvgRenderer/SvgRendererTypes';
import { Link } from '../common/Link';
import styles from './CardGrid.module.scss';

export interface CardGridProps extends ContentType {
  cards: Array<CardProps>;
}

interface CardProps extends ContentType {
  title: string;
  asset: {
    initialAsset: {
      file: {
        url: string;
      };
    };
  };
  body: string;
  url: string;
  urlCopy: string;
  onlyDesktop?: boolean;
  bigImage?: boolean;
  featured?: Array<LinkProps>;
}

export const CardGrid = (props: CardGridProps & Testid) => {
  const bp = useBreakpoint();

  const {
    data: { strings: { altTextLabels } = {} },
    toggleExpanded,
    closeAllMenus
  } = useNavContext();
  const {
    'card-grid': cardContainerClass,
    'card': cardClass,
    'card-content': cardContentClass,
    'featured-list': featuredList,
    'arrow-link': arrowLink,
    'image-wrapper': imageWrapper,
    'big-image': bigImageClass,
    'only-desktop': onlyDesktop,
    title,
    current
  } = styles;

  const handleSelection = (url: string) => {
    window.location.assign(url);

    // This conditional is used for closing the mobile menu "manually" when the only thing which changes in the URL is the # and the id reference
    if (url.includes('#') && window.location.pathname.includes(url.replace(/#.*$/, ''))) {
      if (bp === Breakpoint.SM) {
        toggleExpanded();
      }

      closeAllMenus();
    }
  };

  const handleCardClick = (card: CardProps) => {
    handleSelection(card.url);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLElement>, card: CardProps) => {
    e.preventDefault();
    if (e.key === Keys.Enter) {
      handleSelection(card.url);
    }
  };

  return (
    <div className={cardContainerClass}>
      {props.cards?.map((card, index) => {
        const imageUrl = card.asset?.initialAsset?.file?.url;
        return (
          <div
            className={classes(cardClass, {
              [onlyDesktop]: !!card.onlyDesktop,
              [current]:
                card.url === `${window.location.origin}${window.location.pathname}` ||
                window.location.pathname.endsWith(card.url)
            })}
            key={generateKey()}
            onClick={() => handleCardClick(card)}
            role="button"
            onKeyUp={(e) => handleKeyUp(e, card)}
            tabIndex={-1}
            data-testid={`${props.testid}-card-details-${index}`}
          >
            <div className={cardContentClass}>
              <div className={classes(imageWrapper, { [bigImageClass]: !!card.bigImage })}>
                <img
                  src={`https:${imageUrl}`}
                  alt=""
                  width={bp === 'sm' ? 50 : 100}
                  height={bp === 'sm' ? 50 : 100}
                />
              </div>
              <div>
                <span className={title}>{card.title}</span>
                <p>{card.body}</p>
              </div>
              {card.featured && card.featured.length > 0 && (
                <ul className={featuredList}>
                  <li>{altTextLabels?.featured}</li>
                  {card.featured.map((featuredLink) => (
                    <li key={generateKey()}>
                      <Link href={featuredLink.url}>{featuredLink.copy}</Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <Link className={arrowLink} href={card.url}>
              <span>{card.urlCopy}</span>
              <SvgRenderer name={SvgEnum.CHEVRON_RIGHT} />
            </Link>
          </div>
        );
      })}
    </div>
  );
};
