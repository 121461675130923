import { useEffect } from 'react';
import { Link } from '../common/Link';
import styles from './CtaDrawer.module.scss';
import { Testid } from '../../types';

export interface CtaDrawerProps {
  primaryCopy: string;
  primaryUrl: string;
  secondaryCopy?: string;
  secondaryUrl?: string;
}

export const CtaDrawer = (props: CtaDrawerProps & Testid) => {
  const { 'cta-drawer': ctaDrawer } = styles;
  const { primaryCopy, primaryUrl, secondaryCopy, secondaryUrl } = props;
  const testPrefix = `${props.testid}-cta-drawer`;

  function getBoxShadowStyle(el: HTMLDivElement): string {
    return el.scrollTop + el.clientHeight >= el.scrollHeight
      ? 'none'
      : styles['var-drawer-box-shadow'];
  }

  useEffect(() => {
    const containers = document.querySelectorAll<HTMLDivElement>(
      '[class*=lower-container], [class*=mobile-nav-dropdown]'
    );

    setTimeout(() => {
      containers.forEach((container) => {
        const targetCtaDrawer =
          container.parentElement?.querySelector<HTMLDivElement>('[class*=cta-drawer]');

        if (targetCtaDrawer) {
          targetCtaDrawer.style.boxShadow = getBoxShadowStyle(container);
        }

        container.addEventListener('scroll', handleBoxShadow);
      });
    });

    return () =>
      containers.forEach((container) => container.removeEventListener('scroll', handleBoxShadow));
  }, []);

  function handleBoxShadow(e: Event) {
    const element = e.currentTarget as HTMLDivElement;
    const targetCtaDrawer =
      element.parentElement?.querySelector<HTMLDivElement>('[class*=cta-drawer]');

    if (targetCtaDrawer) {
      targetCtaDrawer.style.boxShadow = getBoxShadowStyle(element);
    }
  }

  return (
    <div className={ctaDrawer}>
      {primaryUrl && (
        <Link href={primaryUrl} aria-label={primaryCopy} testid={`${testPrefix}-primary`}>
          {primaryCopy}
        </Link>
      )}
      {secondaryUrl && (
        <Link href={secondaryUrl} aria-label={secondaryCopy} testid={`${testPrefix}-secondary`}>
          {secondaryCopy}
        </Link>
      )}
    </div>
  );
};
