import { SvgProps } from '../SvgRendererTypes';

export const AstroRobotIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Astro Robot (eba2)</title>
      <path
        fill={fillColor}
        d="M9.86999 4.83008C9.13999 4.83008 8.54999 5.43008 8.54999 6.18008C8.54999 6.93008 9.13999 7.53008 9.86999 7.53008C10.6 7.53008 11.19 6.93008 11.19 6.18008C11.19 5.44008 10.6 4.83008 9.86999 4.83008Z"
      />
      <path
        fill={fillColor}
        d="M14.05 4.83008C13.32 4.83008 12.73 5.43008 12.73 6.18008C12.73 6.93008 13.32 7.53008 14.05 7.53008C14.78 7.53008 15.37 6.93008 15.37 6.18008C15.38 5.44008 14.78 4.83008 14.05 4.83008Z"
      />
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.63 11.76L19.92 18.52C19.94 18.6 20 18.99 20 19.12V20.19C20 21.19 19.21 22 18.23 22H17.62C16.64 22 15.85 21.19 15.85 20.19V19.74H8.15V20.19C8.15 21.19 7.36 22 6.38 22H5.77C4.79 22 4 21.19 4 20.19V19.12C4 19.01 4.02 18.64 4.03 18.57L5.32 11.81C5.41 11.34 5.81 11 6.28 11H9.87V10.37H7.17C6.53 10.37 6.01 9.84 6.01 9.18V3.19C6.01 2.53 6.53 2 7.17 2H16.82C17.46 2 17.98 2.53 17.98 3.19V9.17C17.98 9.83 17.46 10.36 16.82 10.36H13.83V10.99H17.68C18.13 10.99 18.53 11.31 18.63 11.76ZM6.99 3.19V9.18C6.99 9.28 7.07 9.37 7.17 9.37H16.82C16.93 9.37 17.01 9.28 17.01 9.18V3.19C17.01 3.08 16.92 3 16.82 3H7.17C7.07 3 6.99 3.08 6.99 3.19ZM15.71 14.52V12H8.26V14.52C8.26 15.57 9.1 16.43 10.13 16.43H13.84C14.87 16.43 15.71 15.57 15.71 14.52ZM7.28 12H6.28L5 18.75H18.98L17.68 12H16.69V14.52C16.69 16.13 15.41 17.43 13.84 17.43H10.13C8.56 17.43 7.28 16.12 7.28 14.52V12ZM6.38 21C6.82 21 7.17 20.64 7.17 20.19H7.18V19.74H4.98V20.19C4.98 20.64 5.33 21 5.77 21H6.38ZM17.62 21H18.23C18.67 21 19.02 20.64 19.03 20.18V19.73C19.01 19.74 19 19.74 18.98 19.74H16.83V20.19C16.83 20.64 17.18 21 17.62 21Z"
      />
    </svg>
  );
};
