import { SvgProps } from '../SvgRendererTypes';

export const FindIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Find Icon</title>
      <path
        fill={fillColor}
        d="M20.76 19.48L16.24 13.84C16.18 13.76 16.09 13.72 16.01 13.66C16.8 12.64 17.32 11.41 17.47 10.04C17.91 6.06 15.04 2.48 11.05 2.04C10.79 2.01 10.52 2 10.25 2C6.60002 2 3.45002 4.74 3.04002 8.45C2.60002 12.43 5.47002 16.01 9.46002 16.45C9.73002 16.48 10 16.49 10.27 16.49C11.41 16.49 12.5 16.22 13.47 15.74C13.51 15.82 13.53 15.9 13.59 15.97L18.11 21.61C18.33 21.88 18.64 22.02 18.96 22.02C19.2 22.02 19.44 21.94 19.64 21.78L20.59 21.02C21.06 20.64 21.14 19.95 20.76 19.48ZM10.27 15.49C10.04 15.49 9.80002 15.48 9.57002 15.45C7.91002 15.27 6.42002 14.45 5.37002 13.15C4.33002 11.85 3.85002 10.22 4.03002 8.56C4.39002 5.39 7.06002 3 10.25 3C10.48 3 10.72 3.01 10.95 3.04C12.61 3.22 14.1 4.04 15.15 5.34C16.2 6.64 16.67 8.27 16.49 9.93C16.32 11.49 15.58 12.85 14.5 13.84L14.19 14.09C13.11 14.96 11.75 15.49 10.27 15.49ZM19.97 20.23L19.02 20.99C19.01 21 18.99 21.01 18.96 21.01C18.94 21.01 18.91 21 18.89 20.98L14.37 15.34C14.34 15.31 14.35 15.24 14.38 15.21L14.48 15.13C14.71 14.97 14.93 14.79 15.14 14.6L15.34 14.44C15.35 14.43 15.37 14.42 15.4 14.42C15.42 14.42 15.45 14.43 15.47 14.45L19.99 20.09C20.01 20.14 20 20.21 19.97 20.23Z"
      />
    </svg>
  );
};
