import { SvgEnum } from './components/SvgRenderer/SvgRendererTypes';

export enum ContentTypes {
  GlobalNav = 'gnGlobalNav',
  Menu = 'gnMenu',
  SubMenu = 'gnSubMenu',
  SubMenuItem = 'gnSubMenuItem',
  LinkStackContainer = 'gnLinkStackContainer',
  LinkStack = 'gnLinkStack',
  ProductRow = 'gnProductRow',
  Product = 'gnProduct',
  Badge = 'gnBadge',
  Panel = 'gnPanel',
  BlocksContainer = 'gnBlocksContainer',
  Block = 'gnBlock',
  Svgs = 'globalNavSvgs',
  Title = 'gnTitle',
  CardGrid = 'gnCardGrid',
  Card = 'gnCard',
  Link = 'gnLink'
}

export interface ContentType {
  contentType: ContentTypes;
}

interface StringWithUrl {
  label?: string;
  url?: string;
}

export interface NavStrings {
  user: Partial<{
    cart: {
      label?: string;
    };
    help: StringWithUrl;
    search: StringWithUrl;
    status: Partial<{
      signUp: StringWithUrl;
      loggedIn: StringWithUrl;
      loggedOut: StringWithUrl;
      primaryCTA: StringWithUrl;
      secondaryCTA: StringWithUrl;
    }>;
  }>;
  altTextLabels: Partial<{
    back: string;
    menu: string;
    ring: string;
    close: string;
    navigation: string;
    iconGeneral: string;
    featured: string;
  }>;
}

export interface Svg extends ContentType {
  icon: SvgEnum;
}

export interface ShopifyProduct extends ContentType {
  product: {
    title: string;
    handle: string;
    vendor: string;
    variants: Array<{
      title: string;
      price: string;
      availableForSale: boolean;
    }>;
    images: Array<{
      altText: string;
      src: string;
    }>;
  };
}

export interface LinkProps extends ContentType {
  url: string;
  copy: string;
}

export enum Keys {
  Up = 'ArrowUp',
  Down = 'ArrowDown',
  Left = 'ArrowLeft',
  Right = 'ArrowRight',
  Escape = 'Escape',
  Enter = 'Enter',
  Tab = 'Tab',
  Shift = 'Shift',
  End = 'End'
}

export interface Testid {
  testid: string;
}
