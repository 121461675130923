import { SvgProps } from '../SvgRendererTypes';

export const QuestionmarkCircleIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Questionmark Circle Icon</title>
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.56 14C12.556 13.951 12.554 13.893 12.554 13.835C12.554 13.079 12.929 12.41 13.503 12.004C15.12 10.829 15.51 9.939 15.51 8.849C15.5 6.939 14.31 5.999 12.24 5.999C11.195 6.014 10.241 6.389 9.492 7.005L9.5 8.829H9.56C10.156 8.244 10.961 7.871 11.852 7.829C12.86 7.829 13.52 8.229 13.52 9.239C13.52 9.989 13.08 10.579 12.1 11.399C11.465 11.929 11.064 12.721 11.064 13.606C11.064 13.766 11.077 13.923 11.102 14.075L12.56 14.058V14ZM13 16.76C13.003 16.727 13.005 16.689 13.005 16.651C13.005 15.994 12.472 15.461 11.815 15.461C11.158 15.461 10.625 15.994 10.625 16.651C10.625 16.689 10.627 16.727 10.63 16.765C10.626 16.793 10.625 16.831 10.625 16.869C10.625 17.526 11.158 18.059 11.815 18.059C12.472 18.059 13.005 17.526 13.005 16.869C13.005 16.831 13.003 16.793 13 16.755V16.76ZM12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3ZM12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2Z"
      />
    </svg>
  );
};
