import { SvgProps } from '../SvgRendererTypes';

export const HeadphonesIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Headphones Icon</title>
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.07 10.5H19.5C19.5 6.358 16.142 3 12 3C7.858 3 4.5 6.358 4.5 10.5H3.5C2.672 10.5 2 11.172 2 12V16.57C2 17.084 2.416 17.5 2.93 17.5H6V10.5H5.5C5.5 6.91 8.41 4 12 4C15.59 4 18.5 6.91 18.5 10.5H18V20H14V18.5C14 18.224 13.776 18 13.5 18H10.5C10.224 18 10 18.224 10 18.5V20.5C10 20.776 10.224 21 10.5 21H18.5C18.776 21 19 20.776 19 20.5V17.5H20.5C21.328 17.5 22 16.828 22 16V11.43C22 10.916 21.584 10.5 21.07 10.5ZM5 16.5H3V12C3 11.724 3.224 11.5 3.5 11.5H5V16.5ZM13 20H11V19H13V20ZM21 16C21 16.276 20.776 16.5 20.5 16.5H19V11.5H21V16Z"
      />
    </svg>
  );
};
