import { SvgProps } from '../SvgRendererTypes';

export const CarCamIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Car Cam Icon</title>
      <path
        fill={fillColor}
        d="M22 9.5001V9.0001C22 8.73488 21.8946 8.48053 21.7071 8.29299C21.5196 8.10545 21.2652 8.0001 21 8.0001H19.71L19.25 4.7201C19.1821 4.23954 18.9417 3.80008 18.5738 3.48363C18.2058 3.16718 17.7353 2.99532 17.25 3.0001H6.72999C6.24469 2.99532 5.7742 3.16718 5.40623 3.48363C5.03826 3.80008 4.79792 4.23954 4.72999 4.7201L4.28999 8.0001H2.99999C2.73478 8.0001 2.48042 8.10545 2.29289 8.29299C2.10535 8.48053 1.99999 8.73488 1.99999 9.0001V9.5001C1.99999 9.63271 2.05267 9.75988 2.14644 9.85365C2.24021 9.94742 2.36739 10.0001 2.49999 10.0001H2.99999C2.35232 10.7345 1.99651 11.6809 1.99999 12.6601V20.0001C1.99999 20.2653 2.10535 20.5197 2.29289 20.7072C2.48042 20.8947 2.73478 21.0001 2.99999 21.0001H4.99999C5.26521 21.0001 5.51956 20.8947 5.7071 20.7072C5.89464 20.5197 5.99999 20.2653 5.99999 20.0001V18.0001H18V20.0001C18 20.2653 18.1054 20.5197 18.2929 20.7072C18.4804 20.8947 18.7348 21.0001 19 21.0001H21C21.2652 21.0001 21.5196 20.8947 21.7071 20.7072C21.8946 20.5197 22 20.2653 22 20.0001V12.6501C22.001 11.6744 21.6454 10.7319 21 10.0001H21.54C21.6656 9.99002 21.7827 9.93293 21.868 9.84023C21.9532 9.74753 22.0004 9.62606 22 9.5001ZM5.73999 4.8601C5.77396 4.61982 5.89413 4.40009 6.07811 4.24186C6.2621 4.08364 6.49734 3.99771 6.73999 4.0001H17.27C17.5126 3.99771 17.7479 4.08364 17.9319 4.24186C18.1159 4.40009 18.236 4.61982 18.27 4.8601L18.85 9.0001H5.14999L5.73999 4.8601ZM4.99999 20.0001H2.99999V18.0001H4.99999V20.0001ZM21 20.0001H19V18.0001H21V20.0001ZM21 17.0001H2.99999V12.6601C3.00034 12.2639 3.07916 11.8717 3.23192 11.5061C3.38467 11.1405 3.60834 10.8088 3.88999 10.5301L4.40999 10.0001H19.59L20.12 10.5301C20.6828 11.0922 20.9993 11.8547 21 12.6501V17.0001Z"
      />
      <path
        fill={fillColor}
        d="M18.5 15.0001C19.3284 15.0001 20 14.3285 20 13.5001C20 12.6717 19.3284 12.0001 18.5 12.0001C17.6716 12.0001 17 12.6717 17 13.5001C17 14.3285 17.6716 15.0001 18.5 15.0001Z"
      />
      <path
        fill={fillColor}
        d="M5.49999 15.0001C6.32842 15.0001 6.99999 14.3285 6.99999 13.5001C6.99999 12.6717 6.32842 12.0001 5.49999 12.0001C4.67157 12.0001 3.99999 12.6717 3.99999 13.5001C3.99999 14.3285 4.67157 15.0001 5.49999 15.0001Z"
      />
      <path
        fill={fillColor}
        d="M7.99999 14.0001C7.99999 14.2653 8.10535 14.5197 8.29289 14.7072C8.48042 14.8947 8.73478 15.0001 8.99999 15.0001H15C15.2652 15.0001 15.5196 14.8947 15.7071 14.7072C15.8946 14.5197 16 14.2653 16 14.0001V12.0001H7.99999V14.0001Z"
      />
    </svg>
  );
};
