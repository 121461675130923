let index = 0;
export const generateKey = (seed = '') => {
  return `${seed}${index++}`;
};

type ClassNameInput = { [className: string]: boolean } | string | undefined;

export const classes = (...config: Array<ClassNameInput>): string | undefined => {
  const result: Array<string> = [];

  for (const rule of config) {
    if (typeof rule === 'string') {
      result.push(rule);
    } else if (typeof rule === 'object') {
      for (const [key, value] of Object.entries(rule)) {
        if (value) {
          result.push(key);
        }
      }
    }
  }

  return result.length ? result.join(' ') : undefined;
};

export const pixelsToNumber = (pixels: string): number => +pixels.replace(/px/gi, '');

export const appendQueryParamsToUrl = (url: string, query: { [key: string]: string | number }) => {
  const params = new URLSearchParams();
  Object.entries(query).forEach(([key, value]) => params.append(key, value.toString()));
  return `${url}?${params.toString()}`;
};
