import { SvgProps } from '../SvgRendererTypes';

export const CartIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Cart Icon</title>
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 6L5.7 3H2.5C2.224 3 2 3.224 2 3.5C2 3.776 2.224 4 2.5 4H4.79L6 16H20.17L22 6H6ZM19.33 15H6.89L6.1 7H20.83L19.33 15ZM18 17C16.895 17 16 17.895 16 19C16 20.105 16.895 21 18 21C19.105 21 20 20.105 20 19C20 17.895 19.105 17 18 17ZM18 20C17.448 20 17 19.552 17 19C17 18.448 17.448 18 18 18C18.552 18 19 18.448 19 19C19 19.552 18.552 20 18 20ZM8 17C6.895 17 6 17.895 6 19C6 20.105 6.895 21 8 21C9.105 21 10 20.105 10 19C10 17.895 9.105 17 8 17ZM8 20C7.448 20 7 19.552 7 19C7 18.448 7.448 18 8 18C8.552 18 9 18.448 9 19C9 19.552 8.552 20 8 20Z"
      />
    </svg>
  );
};
