import { SvgProps } from '../SvgRendererTypes';

export const SearchIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Search Icon</title>
      <path
        fill={fillColor}
        d="M21.2596 19.48L16.7396 13.84C16.6796 13.76 16.5896 13.72 16.5096 13.66C17.2996 12.64 17.8196 11.41 17.9696 10.04C18.4096 6.06 15.5396 2.48 11.5496 2.04C11.2896 2.01 11.0196 2 10.7496 2C7.09965 2 3.94965 4.74 3.53965 8.45C3.09965 12.43 5.96965 16.01 9.95965 16.45C10.2296 16.48 10.4997 16.49 10.7696 16.49C11.9096 16.49 12.9996 16.22 13.9696 15.74C14.0096 15.82 14.0296 15.9 14.0896 15.97L18.6096 21.61C18.8296 21.88 19.1396 22.02 19.4596 22.02C19.6996 22.02 19.9396 21.94 20.1396 21.78L21.0896 21.02C21.5596 20.64 21.6396 19.95 21.2596 19.48ZM10.7696 15.49C10.5396 15.49 10.2996 15.48 10.0696 15.45C8.40965 15.27 6.91965 14.45 5.86965 13.15C4.82965 11.85 4.34965 10.22 4.52965 8.56C4.88965 5.39 7.55965 3 10.7496 3C10.9796 3 11.2196 3.01 11.4496 3.04C13.1096 3.22 14.5996 4.04 15.6496 5.34C16.6996 6.64 17.1696 8.27 16.9896 9.93C16.8196 11.49 16.0796 12.85 14.9996 13.84L14.6896 14.09C13.6096 14.96 12.2497 15.49 10.7696 15.49ZM20.4696 20.23L19.5196 20.99C19.5096 21 19.4896 21.01 19.4596 21.01C19.4396 21.01 19.4096 21 19.3896 20.98L14.8696 15.34C14.8396 15.31 14.8496 15.24 14.8796 15.21L14.9796 15.13C15.2096 14.97 15.4296 14.79 15.6396 14.6L15.8396 14.44C15.8496 14.43 15.8696 14.42 15.8996 14.42C15.9196 14.42 15.9496 14.43 15.9696 14.45L20.4896 20.09C20.5096 20.14 20.4996 20.21 20.4696 20.23Z"
      />
    </svg>
  );
};
