import { SvgProps } from '../SvgRendererTypes';

export const MenuIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Menu Icon</title>
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 4H2.5C2.224 4 2 4.224 2 4.5C2 4.776 2.224 5 2.5 5H21.5C21.776 5 22 4.776 22 4.5C22 4.224 21.776 4 21.5 4ZM21.5 11H2.5C2.224 11 2 11.224 2 11.5C2 11.776 2.224 12 2.5 12H21.5C21.776 12 22 11.776 22 11.5C22 11.224 21.776 11 21.5 11ZM21.5 18H2.5C2.224 18 2 18.224 2 18.5C2 18.776 2.224 19 2.5 19H21.5C21.776 19 22 18.776 22 18.5C22 18.224 21.776 18 21.5 18Z"
      />
    </svg>
  );
};
