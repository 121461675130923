import { useState } from 'react';
import { useBreakpoint, useNavContext } from '../../hooks';
import { Breakpoint } from '../../static/constants';
import { classes, generateKey } from '../../utils';
import { Button } from '../common/Button';
import { Link } from '../common/Link';
import { ContentType, Svg, Testid } from '../../types';
import { Panel, PanelProps } from '../Panel/Panel';
import { SvgRenderer } from '../SvgRenderer/SvgRenderer';
import { SvgEnum } from '../SvgRenderer/SvgRendererTypes';
import { CtaDrawer, CtaDrawerProps } from '../CtaDrawer/CtaDrawer';

import styles from './SubMenu.module.scss';

export interface SubMenuProps extends ContentType {
  items: Array<SubMenuItemProps>;
  ctaDrawer?: CtaDrawerProps;
}

interface SubMenuItemProps extends ContentType {
  id?: number;
  content: PanelProps;
  icon: Svg;
  label: string;
  url: string;
  urlLabel: string;
  onlyDesktop: boolean;
  tag?: 'NEW';
  tagColor?: 'Blue';
  bottomLink?: boolean;
}

export const SubMenu = (props: SubMenuProps & Testid) => {
  const {
    submenu,
    link,
    'menu-items': menuItems,
    'chevron-wrapper': chevronWrapper,
    'active-btn': activeBtn,
    'only-desktop': onlyDesktopClass
  } = styles;
  const [currentItem, setCurrentItem] = useState(0);
  const { openMenu } = useNavContext();
  const bp = useBreakpoint();
  const isLG = bp === Breakpoint.LG;

  const handleElementClick = (index: number) => {
    if (bp === Breakpoint.LG) {
      window.location.assign(props.items[index].url);
    } else {
      const { label, content, contentType, icon } = props.items[index];
      openMenu({
        label,
        content,
        contentType,
        icon
      });
    }
  };

  const getTagClass = (tagColor: SubMenuItemProps['tagColor']): string => {
    return styles[`${tagColor?.toLowerCase()}-tag`] || '';
  };

  // split the items in two groups
  const itemGroups: Array<Array<SubMenuItemProps>> = [[], []];
  props.items.forEach((item, index) => {
    const group = item.bottomLink ? itemGroups[1] : itemGroups[0];
    group.push({ ...item, id: index });
  });

  return (
    <div className={submenu}>
      <div className={menuItems}>
        {itemGroups.map(
          (items) =>
            items.length > 0 && (
              <ul key={generateKey()}>
                {items.map(
                  (
                    {
                      id,
                      label,
                      content,
                      icon,
                      url,
                      onlyDesktop,
                      tag,
                      tagColor,
                      bottomLink = false
                    },
                    index
                  ) => {
                    return (
                      <li
                        className={classes({ [onlyDesktopClass]: onlyDesktop })}
                        key={generateKey(label)}
                      >
                        {bottomLink ? (
                          <Link
                            href={url}
                            className={classes({
                              [link]: true,
                              [activeBtn]: currentItem === id
                            })}
                            testid={`${props.testid}-sub-menu-${index + itemGroups[0].length}`}
                            onMouseEnter={() => content && setCurrentItem(id as number)}
                          >
                            <SvgRenderer name={icon.icon} />
                            {label}
                          </Link>
                        ) : (
                          <Button
                            className={classes({ [activeBtn]: currentItem === id })}
                            onClick={() => handleElementClick(id as number)}
                            onMouseEnter={() => setCurrentItem(id as number)}
                            testid={`${props.testid}-sub-menu-${index}`}
                            ariaLabel={label}
                          >
                            <SvgRenderer name={icon.icon} />
                            {label}
                            {tag && <span className={getTagClass(tagColor)}>{tag}</span>}
                            <span className={chevronWrapper}>
                              <SvgRenderer name={SvgEnum.CHEVRON_RIGHT} />
                            </span>
                          </Button>
                        )}
                      </li>
                    );
                  }
                )}
              </ul>
            )
        )}
      </div>
      {props.items.map(({ content, url }, index) => (
        <Panel
          {...content}
          key={generateKey(url)}
          active={isLG && currentItem === index}
          testid={`${props.testid}-${index}`}
        />
      ))}
      {props.ctaDrawer && <CtaDrawer {...props.ctaDrawer} testid={`${props.testid}-submenu`} />}
    </div>
  );
};
