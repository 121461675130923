import { ContentType, ContentTypes, Testid } from '../../types';
import { classes, generateKey } from '../../utils';
import { BlocksContainer, BlocksContainerProps } from '../BlocksContainer/BlocksContainer';
import { CtaDrawer, CtaDrawerProps } from '../CtaDrawer/CtaDrawer';
import { Title, TitleProps } from '../Title/Title';
import {
  LinkStackContainer,
  LinkStackContainerProps
} from '../LinkStackContainer/LinkStackContainer';
import { ProductRow, ProductRowProps } from '../ProductRow/ProductRow';
import styles from './Panel.module.scss';
import { CardGridProps, CardGrid } from '../CardGrid/CardGrid';

export interface PanelProps extends ContentType {
  content: Array<
    BlocksContainerProps | LinkStackContainerProps | ProductRowProps | TitleProps | CardGridProps
  >;
  ctaDrawer?: CtaDrawerProps;
  active?: boolean;
}

export const Panel = (props: PanelProps & Testid) => {
  const { panel, 'panel-content': panelContent, 'visible': visibleClass } = styles;

  return (
    <div className={classes(panel, { [visibleClass]: props.active === true })}>
      <div className={panelContent}>
        {props.content &&
          props.content.map(
            (component) =>
              (component.contentType === ContentTypes.CardGrid && (
                <CardGrid
                  {...(component as CardGridProps)}
                  key={generateKey(component.contentType)}
                  testid={props.testid}
                />
              )) ||
              (component.contentType === ContentTypes.Title && (
                <Title
                  {...(component as TitleProps)}
                  key={generateKey(component.contentType)}
                  testid={props.testid}
                />
              )) ||
              (component.contentType === ContentTypes.BlocksContainer && (
                <BlocksContainer
                  {...(component as BlocksContainerProps)}
                  key={generateKey(component.contentType)}
                  testid={props.testid}
                />
              )) ||
              (component.contentType === ContentTypes.ProductRow && (
                <ProductRow
                  {...(component as ProductRowProps)}
                  key={generateKey(component.contentType)}
                />
              )) ||
              (component.contentType === ContentTypes.LinkStackContainer && (
                <LinkStackContainer
                  {...(component as LinkStackContainerProps)}
                  key={generateKey(component.contentType)}
                />
              ))
          )}
      </div>
      {props.ctaDrawer && <CtaDrawer {...props.ctaDrawer} testid={`${props.testid}-panel`} />}
    </div>
  );
};
