import { SvgProps } from '../SvgRendererTypes';

export const ChevronLeftIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} fill="none">
      <title>Chevron Left Icon</title>
      <path
        data-active="stroke"
        d="M15 5L8 11.9815L15 19"
        stroke={fillColor}
        strokeWidth="1.9"
        strokeLinecap="round"
      />
    </svg>
  );
};
