import { SvgProps } from '../SvgRendererTypes';

export const InstallationServicesIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Installation Services Icon</title>
      <path
        fill={fillColor}
        d="M13.8 11.32L18.78 6.47998L19.89 6.34998L21 4.21998L20.4 3.56998L18.23 4.75998L18.12 5.72998L13.11 10.6H13.1C13.05 10.55 13 10.51 12.94 10.46L10.56 8.10998C10.69 7.71998 10.77 7.31998 10.77 6.89998C10.77 5.87998 10.37 4.91998 9.63999 4.18998C8.37999 2.93998 6.88998 2.67998 5.47998 3.45998L4.88998 3.78998L7.10999 5.97998L6.93999 6.89998L6.00999 7.03998L3.79999 4.83998L3.46999 5.40998C2.66999 6.84998 2.92998 8.33998 4.19998 9.60998C4.94998 10.36 5.92999 10.73 6.91999 10.73C7.25999 10.73 7.58999 10.67 7.91999 10.58L8.95999 11.61L3.53999 16.63C2.82999 17.29 2.81999 18.71 3.50999 19.47L4.28999 20.3C4.70999 20.75 5.27998 20.98 5.82998 20.98C6.30998 20.98 6.77999 20.81 7.13999 20.48L12.71 15.32L18.48 21L21 18.41L13.8 11.32ZM8.19999 9.41998C7.12998 9.94998 5.79998 9.77998 4.90998 8.89998C4.14998 8.14998 3.87999 7.34998 4.08999 6.53998L5.66999 8.10998L7.77999 7.77998L8.19999 5.63998L6.60999 4.07998C7.40999 3.87998 8.16998 4.15998 8.93998 4.90998C9.47998 5.43998 9.76999 6.14998 9.76999 6.90998C9.76999 7.41998 9.61999 7.89998 9.36999 8.32998L11.06 9.99998C10.76 10.08 10.48 10.22 10.24 10.44L9.71999 10.93L8.19999 9.41998ZM6.46998 19.74C6.29998 19.89 6.07999 19.98 5.83999 19.98C5.53999 19.98 5.23999 19.85 5.02999 19.62L4.24999 18.79C4.03999 18.57 3.93999 18.29 3.94999 18C3.95999 17.75 4.05999 17.52 4.22999 17.36L4.33999 17.25L6.57998 19.63L6.46998 19.74ZM13.16 13.54L12.46 14.19L12 14.61L7.29999 18.97L5.06999 16.59L10.92 11.17C11.09 11.02 11.31 10.93 11.55 10.93C11.85 10.93 12.15 11.06 12.36 11.29L13.14 12.12C13.35 12.34 13.45 12.62 13.44 12.91C13.44 13.15 13.34 13.38 13.16 13.54ZM18.46 19.58L13.46 14.63L13.84 14.28C14.13 14.01 14.31 13.67 14.4 13.3L19.59 18.43L18.46 19.58Z"
      />
    </svg>
  );
};
