import { SvgProps } from '../SvgRendererTypes';

export const PetSettingsIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Pet Settings Icon</title>
      <circle fill={fillColor} cx="9" cy="10" r="1" />
      <circle fill={fillColor} cx="15" cy="10" r="1" />
      <path
        fill={fillColor}
        d="M15.62,3A17,17,0,0,0,12,2.5,17,17,0,0,0,8.38,3C6.66,3.1,2,3.55,2,6c0,1.19,1,6,2,6a1.87,1.87,0,0,0,1-.45c0,1.94.28,6,2.09,7.89a3.24,3.24,0,0,0,1.6.95A4.5,4.5,0,0,0,12,22a4.68,4.68,0,0,0,3.32-1.61,3.27,3.27,0,0,0,1.61-.95c1.81-1.91,2.07-6,2.09-7.89A1.87,1.87,0,0,0,20,12c1,0,2-4.81,2-6C22,3.55,17.34,3.1,15.62,3ZM4.21,10.91A16.07,16.07,0,0,1,3,6C3,4.89,5.42,4.29,7.47,4.08A16.13,16.13,0,0,0,7.11,6.9c0,.29,0,.65-.06.78A12.77,12.77,0,0,1,4.21,10.91Zm5.92,9.48A3.37,3.37,0,0,0,12,18.84a3.42,3.42,0,0,0,1.84,1.54A3.52,3.52,0,0,1,12,21,3.56,3.56,0,0,1,10.13,20.39Zm6.08-1.64a2.29,2.29,0,0,1-1.71.75,2.37,2.37,0,0,1-1.94-1.94L13.91,16c.23-.4-.62-.95-1.91-.95s-2.14.55-1.91.95l1.35,1.61A2.36,2.36,0,0,1,9.5,19.5a2.25,2.25,0,0,1-1.7-.75C5.87,16.72,6,11.46,6,10.64A12,12,0,0,0,8,8a28.8,28.8,0,0,1,.53-4A16.2,16.2,0,0,1,12,3.5,16.2,16.2,0,0,1,15.47,4,28.8,28.8,0,0,1,16,8a12,12,0,0,0,2,2.64C18,11.46,18.13,16.72,16.21,18.75ZM19.8,10.9A12.68,12.68,0,0,1,17,7.68c0-.13,0-.49-.06-.78a15.23,15.23,0,0,0-.36-2.82c2,.2,4.47.81,4.47,1.92A16.13,16.13,0,0,1,19.8,10.9Z"
      />
    </svg>
  );
};
