import { Testid } from '../../types';

interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;
  className?: string;
  children?: React.ReactNode;
  beforeNavigation?: () => Promise<void>;
}

export const Link = ({
  href,
  className,
  testid,
  children,
  beforeNavigation,
  ...props
}: LinkProps & Partial<Testid>) => {
  async function handleNavigation() {
    try {
      await beforeNavigation?.();
    } catch (error) {
      console.log(error);
    } finally {
      window.location.assign(`${location.origin}${href}`);
    }
  }

  return (
    <a
      {...props}
      href={beforeNavigation ? undefined : href}
      className={className}
      data-testid={testid}
      onClick={beforeNavigation ? handleNavigation : undefined}
    >
      {children}
    </a>
  );
};
