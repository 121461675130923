import { SvgProps } from '../SvgRendererTypes';

export const FloodlightCamIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Floodlight Cam Icon</title>
      <path d="M13.51 11.4102V16.1802H10.56V11.4102H13.51Z" fill={fillColor} />
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.17 4.53C15.6 3.08 16.93 2 18.51 2C20.43 2 22 3.57 22.01 5.47C22.01 7.39 20.44 8.96 18.52 8.96C18.5 8.96 18.48 8.9575 18.46 8.955C18.44 8.9525 18.42 8.95 18.4 8.95C18.48 9.36 18.52 9.78 18.52 10.21C18.52 12.28 17.55 14.13 16.05 15.33V18.19C16.05 18.19 15.86 21.98 12.05 21.98C8.23 21.98 8.05 18.19 8.05 18.19V15.45C6.46 14.25 5.42 12.36 5.42 10.22C5.42 9.79 5.46 9.37 5.54 8.97C5.52 8.98 5.51 8.98 5.49 8.98C3.57 8.98 2 7.41 2 5.49C2 3.57 3.57 2 5.49 2C7.06 2 8.38 3.06 8.81 4.5C9.75 3.98 10.82 3.68 11.96 3.68C13.12 3.68 14.22 3.99 15.17 4.53ZM3 5.49C3 6.87 4.12 7.99 5.49 7.99C5.61 7.99 5.72 7.97 5.83 7.95C6.49 7.86 7.06 7.51 7.45 7.01C7.5 6.95 7.54 6.9 7.58 6.85C7.83 6.46 7.98 6 7.98 5.5C7.98 5.35885 7.96229 5.23542 7.94354 5.10469C7.94236 5.09649 7.94118 5.08826 7.94 5.08C7.74 3.9 6.72 3 5.49 3C4.12 3 3 4.12 3 5.49ZM9.11 18.48C9.35 19.92 10.57 21 12.04 21C13.52 21 14.74 19.91 14.97 18.48H9.11ZM15.04 9.99H9.04V17.48H15.04V9.99ZM16.04 8.99V13.99C16.95 13 17.51 11.68 17.5 10.22C17.5 9.71 17.43 9.22 17.3 8.75C16.01 8.27 15.08 7.05 15.03 5.6C14.15 5.01 13.09 4.67 11.96 4.67C10.86 4.67 9.84 5 8.97 5.56C8.94 7.05 7.96 8.31 6.61 8.78C6.48 9.25 6.41 9.73 6.41 10.23C6.41 11.76 7.03 13.15 8.04 14.16V8.99H16.04ZM18.09 7.95C18.23 7.97 18.37 7.99 18.51 7.99C19.88 7.99 21 6.87 21 5.49C21 4.12 19.88 3 18.51 3C17.26 3 16.24 3.93 16.06 5.13C16.04 5.25 16.02 5.37 16.02 5.5C16.02 6.73 16.92 7.75 18.09 7.95Z"
      />
    </svg>
  );
};
