import { SvgProps } from '../SvgRendererTypes';

export const ChevronDownIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} fill="none">
      <title>Chevron Down Icon</title>
      <path
        fillRule="evenodd"
        stroke={fillColor}
        strokeLinecap="round"
        strokeWidth="1.4"
        d="m5.5 10.5 6.5 4 6.5-4"
      />
    </svg>
  );
};
