import { ContentType, ContentTypes, ShopifyProduct } from '../../types';
import { generateKey } from '../../utils';
import styles from './LinkStackContainer.module.scss';

export interface LinkStackContainerProps extends ContentType {
  stacks: Array<LinkStackProps>;
}
interface LinkStackProps extends ContentType {
  headline: string;
  urlCopy: string;
  url: string;
  products: Array<ShopifyProduct>;
}

export const LinkStackContainer = (props: LinkStackContainerProps) => {
  const { 'link-stack': linkStack, rows, plain } = styles;
  const variations: { [key: string]: string } = {
    plain,
    default: styles.default
  };

  return (
    <div className={linkStack}>
      {props.stacks &&
        props.stacks.map((stack, index) => (
          <ul
            className={rows}
            key={generateKey(ContentTypes.LinkStack)}
            data-testid={`stack-${index}`}
          >
            <li className={variations.plain}>{stack.headline}</li>
            {stack.products &&
              stack.products.map(({ product }, index) => (
                <li key={product.title} data-testid={`list-link-${index}`}>
                  <a
                    className={variations.plain}
                    href={`/products/${product.handle}`}
                    aria-label={product.title}
                  >
                    {product.title}
                  </a>
                </li>
              ))}
            <li>
              <a className={variations.default} href={stack.url}>
                {stack.urlCopy}
              </a>
            </li>
          </ul>
        ))}
    </div>
  );
};
