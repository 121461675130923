import React, { useEffect, useRef, useState } from 'react';
import { useNavContext } from '../../hooks';
import { Defaults, FallbackLinks } from '../../static/constants';
import { Keys } from '../../types';
import { appendQueryParamsToUrl, classes } from '../../utils';
import { Button } from '../common/Button';
import { Link } from '../common/Link';
import { MenuProps } from '../Menu/Menu';
import { SvgRenderer } from '../SvgRenderer/SvgRenderer';
import { SvgEnum } from '../SvgRenderer/SvgRendererTypes';
import { LogoToggle } from '../LogoToggle/LogoToggle';

import styles from './Navigation.module.scss';

export const Navigation = (props: { loginStatus: boolean | undefined }) => {
  const {
    'nav-container': navContainer,
    'icon-container': iconContainer,
    'flex-spacer': flexSpacer,
    'nav-items-list': navItemsList,
    'nav-item': navItem,
    'dropdown-btn': dropdownBtn,
    'lg-only': lgOnly,
    'cart-btn': cartBtn,
    'icon-group': iconGroup,
    'gn-qty': gnQty,
    'nav-items-container': navItemsContainer,
    active,
    login
  } = styles;
  const [focused, setFocused] = useState(-1);
  const navListRef = useRef<HTMLDivElement>(null);
  const navContainerRef = useRef<HTMLDivElement>(null);
  const qtyRef = useRef<HTMLParagraphElement>(null);
  const loggedInLinkClasses = classes(login, iconContainer);
  const [currentMenu, setCurrentMenu] = useState<MenuProps | undefined>(undefined);
  const { data, header, openMenu, activeMenus, closeAllMenus, keyPressed } = useNavContext();
  const loggedOutUrl = data.strings?.user?.status?.loggedOut?.url || FallbackLinks.SIGN_IN;
  const isOnPath = (path: string) => window.location.pathname.includes(path);
  const excludeCartIconPaths = ['/cart', '/checkout'];
  const showCartIcon = data.transactional && excludeCartIconPaths.every((path) => !isOnPath(path));

  useEffect(() => {
    const { key } = keyPressed;
    if ([Keys.Up, Keys.Down, Keys.Escape].includes(key as Keys)) {
      navListRef.current?.focus();
    } else if (key === Keys.End) {
      setFocused(-1);
    }
  }, [keyPressed]);

  useEffect(() => {
    setCurrentMenu(activeMenus.length > 0 ? activeMenus[activeMenus.length - 1] : undefined);
  }, [activeMenus]);

  const handleKeyUp = (e: React.KeyboardEvent<HTMLElement>) => {
    const key = e.key;
    if (!currentMenu) {
      if (key === Keys.Left && focused > 0) {
        setFocused(focused - 1);
      } else if (key === Keys.Right && focused < data.menus.length - 1) {
        setFocused(focused + 1);
      } else if (key === Keys.Enter) {
        openMenu(data.menus[focused]);
      }
    }
  };

  const handleMouseEnter = (item: MenuProps) => {
    setFocused(-1);
    openMenu(item);
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const navigation = navContainerRef.current;
    if (navigation) {
      const bounds = navigation.getBoundingClientRect();
      const shouldCloseMenus =
        e.clientY < bounds.y + bounds.height - 5 || (currentMenu && !currentMenu.content);

      if (shouldCloseMenus) {
        closeAllMenus();
      }
    }
  };

  const handleFocus = () => {
    if (focused < 0 && !currentMenu) {
      setFocused(0);
    }
  };

  const handleBlur = () => {
    if (!currentMenu) {
      setFocused(-1);
    }
  };

  const handleClick = (item: MenuProps) => {
    if (item.url) {
      window.location.assign(item.url);
    }
  };

  return (
    <div className={navContainer} ref={navContainerRef}>
      <LogoToggle
        showToggle={data.menus.length > 0}
        strings={{
          menu: data.strings?.altTextLabels?.menu || Defaults.LABELS.MENU,
          close: data.strings?.altTextLabels?.close || Defaults.LABELS.CLOSE,
          ring: data.strings?.altTextLabels?.ring || Defaults.LABELS.RING
        }}
      />
      <div
        className={navItemsContainer}
        tabIndex={0}
        role="menubar"
        ref={navListRef}
        onMouseLeave={handleMouseLeave}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyUp={handleKeyUp}
      >
        <ul className={navItemsList}>
          {data.menus
            .filter((menu) => !menu.mobileOnly)
            .map((item, index: number) => {
              return (
                <li
                  key={item.label}
                  className={classes(navItem, {
                    [active]: currentMenu?.label === item.label || focused === index
                  })}
                  onMouseEnter={() => handleMouseEnter(item)}
                  tabIndex={-1}
                >
                  <Button
                    className={dropdownBtn}
                    ariaLabel={item.label}
                    tabIndex={-1}
                    testid={`nav-item-${index}`}
                    onClick={() => handleClick(item)}
                    role={item.url ? 'link' : undefined}
                  >
                    {item.label}
                  </Button>
                </li>
              );
            })}
        </ul>
      </div>
      <span className={flexSpacer} />
      {header.shop && (
        <div className={iconGroup}>
          {props.loginStatus ? (
            <Link
              className={loggedInLinkClasses}
              href={data.strings?.user?.status?.loggedIn?.url || FallbackLinks.DASHBOARD}
              aria-label={data.strings?.user?.status?.loggedIn?.label || Defaults.LABELS.DASHBOARD}
              tabIndex={currentMenu ? -1 : 0}
              testid="nav-item-dashboard"
            >
              <SvgRenderer name={SvgEnum.HOME} />
              <p>{data.strings?.user?.status?.loggedIn?.label || Defaults.LABELS.DASHBOARD}</p>
            </Link>
          ) : (
            <Link
              className={login}
              href={
                header.returnTo
                  ? appendQueryParamsToUrl(loggedOutUrl, { return: header.returnTo })
                  : loggedOutUrl
              }
              aria-label={data.strings?.user?.status?.loggedOut?.label || Defaults.LABELS.LOGIN}
              tabIndex={currentMenu ? -1 : 0}
              testid="nav-item-login"
            >
              {data.strings?.user?.status?.loggedOut?.label || Defaults.LABELS.LOGIN}
            </Link>
          )}
          {data.transactional && !isOnPath('/search') && (
            <Link
              className={classes(iconContainer)}
              href={data.strings?.user?.search?.url || FallbackLinks.SEARCH}
              aria-label={data.strings?.user?.search?.label || Defaults.LABELS.SEARCH}
              tabIndex={currentMenu ? -1 : 0}
              testid="nav-item-search"
            >
              <SvgRenderer name={SvgEnum.SEARCH} />
              <p>{data.strings?.user?.search?.label || Defaults.LABELS.SEARCH}</p>
            </Link>
          )}
          <Link
            className={classes(iconContainer, lgOnly)}
            href={data.strings?.user?.help?.url || FallbackLinks.SUPPORT}
            aria-label={data.strings?.user?.help?.label || Defaults.LABELS.HELP}
            tabIndex={currentMenu ? -1 : 0}
            testid="nav-item-help"
          >
            <SvgRenderer name={SvgEnum.HEADPHONES} />
            <p>{data.strings?.user?.help?.label || Defaults.LABELS.HELP}</p>
          </Link>
          {showCartIcon && (
            <Button
              className={classes(iconContainer, cartBtn)}
              ariaLabel={data.strings?.user?.cart?.label || Defaults.LABELS.CART}
              tabIndex={currentMenu ? -1 : 0}
              testid={'nav-item-cart'}
            >
              <SvgRenderer name={SvgEnum.CART} />
              <p>{data.strings?.user?.cart?.label || Defaults.LABELS.CART}</p>
              <p ref={qtyRef} className={classes(gnQty, 'gn-cart-qty')} data-qty={0} />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
