import { Button } from '../common/Button';
import { SvgRenderer } from '../SvgRenderer/SvgRenderer';
import { Link } from '../common/Link';
import { useNavContext } from '../../hooks';
import { SvgEnum } from '../SvgRenderer/SvgRendererTypes';

import styles from './LogoToggle.module.scss';

interface LogoToggleProps {
  showToggle: boolean;
  strings: {
    menu: string;
    close: string;
    ring: string;
  };
}

export const LogoToggle = ({ showToggle, strings }: LogoToggleProps) => {
  const { expanded, toggleExpanded } = useNavContext();

  const { 'logo-toggle': logoToggle, 'toggle': toggle, 'logo-link': logoLink } = styles;

  return (
    <div className={logoToggle}>
      {showToggle && (
        <Button
          className={toggle}
          onClick={toggleExpanded}
          ariaLabel={!expanded ? strings.menu : strings.close}
          testid="menu-toggle"
        >
          <SvgRenderer name={!expanded ? SvgEnum.MENU : SvgEnum.CLOSE_X} />
        </Button>
      )}
      <Link className={logoLink} href="/" aria-label={strings.ring} tabIndex={0} testid="ring-logo">
        <SvgRenderer name={SvgEnum.RING_LOGO} width={48} height={30} viewBox="0 0 1000 640.27" />
      </Link>
    </div>
  );
};
