import { useEffect, useState } from 'react';
import { Breakpoint, BreakpointWidth } from '../static/constants';

interface WindowSizeProps {
  width: number | undefined;
  height: number | undefined;
}

export const useBreakpoint = (): Breakpoint => {
  const [breakpoint, setBreakPoint] = useState(Breakpoint.SM);
  const [windowSize, setWindowSize] = useState<WindowSizeProps>({
    width: undefined,
    height: undefined
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();

    const { width } = windowSize;

    if (width) {
      if (width >= BreakpointWidth.LG) {
        setBreakPoint(Breakpoint.LG);
      } else if (width >= BreakpointWidth.MD && width < BreakpointWidth.LG) {
        setBreakPoint(Breakpoint.MD);
      } else {
        setBreakPoint(Breakpoint.SM);
      }
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [windowSize.width]);

  return breakpoint;
};
