import { SvgProps } from '../SvgRendererTypes';

export const ProfessionalInstallationIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Professional Installation Icon</title>
      <path
        fill={fillColor}
        d="M12.67 15.2002H9.61C9.33 15.2002 9.11 15.4202 9.11 15.7002C9.11 15.9802 9.33 16.2002 9.61 16.2002H12.67C12.95 16.2002 13.17 15.9802 13.17 15.7002C13.17 15.4202 12.95 15.2002 12.67 15.2002Z"
      />
      <path
        fill={fillColor}
        d="M12.67 17.2002H9.61C9.33 17.2002 9.11 17.4202 9.11 17.7002C9.11 17.9802 9.33 18.2002 9.61 18.2002H12.67C12.95 18.2002 13.17 17.9802 13.17 17.7002C13.17 17.4202 12.95 17.2002 12.67 17.2002Z"
      />
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.92 11.67L19.33 11.69C20.25 11.69 21 12.46 21.02 13.41C21.02 14.02 20.71 14.55 20.25 14.85H20.24C20.37 15.1 20.46 15.39 20.46 15.7C20.46 16.31 20.15 16.84 19.69 17.14H19.68C19.81 17.39 19.9 17.68 19.9 17.99C19.9 18.6 19.59 19.13 19.13 19.43H19.12C19.25 19.68 19.34 19.97 19.34 20.28C19.34 21.23 18.59 22 17.67 22H3.56C3.25 22 3 21.75 3 21.44V12.72C3 12.72 2.97 12.02 3.68 12L7.13 11.97L12.19 9.9C12.44 9.38 12.9 8.98 13.47 8.83V5H13.46L12.89 4.24L13.47 2H14.32L15 4.24L14.46 4.96V8.84C15.3 9.07 15.92 9.82 15.92 10.73V11.67ZM13.96 9.76C13.43 9.76 13 10.19 13 10.72V11.66H14.92V10.72C14.92 10.19 14.49 9.76 13.96 9.76ZM4.11 20.89H6.44V13.09H4.11V20.89ZM19.33 13.98C19.64 13.98 19.89 13.73 19.89 13.41C19.89 13.09 19.64 12.84 19.33 12.84L12 12.83V11.32L7.58 12.96L7.56 20.74V20.82H17.66C17.97 20.82 18.22 20.57 18.22 20.25C18.22 19.94 17.97 19.68 17.66 19.68H16.55C16.24 19.68 15.99 19.43 15.99 19.11C15.99 18.8 16.24 18.54 16.55 18.54H18.22C18.53 18.54 18.78 18.29 18.78 17.97C18.78 17.66 18.53 17.4 18.22 17.4H16.55C16.24 17.4 15.99 17.15 15.99 16.83C15.99 16.52 16.24 16.26 16.55 16.26H18.77C19.08 16.26 19.33 16.01 19.33 15.69C19.33 15.38 19.08 15.12 18.77 15.12H16.55C16.24 15.12 15.99 14.87 15.99 14.55C15.99 14.24 16.24 13.98 16.55 13.98H19.33Z"
      />
    </svg>
  );
};
