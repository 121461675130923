import { SvgProps } from '../SvgRendererTypes';

export const ChevronRightIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} fill="none">
      <title>Chevron Right Icon</title>
      <path stroke={fillColor} d="M10 19L17 12.0185L10 5" strokeWidth="1.9" strokeLinecap="round" />
    </svg>
  );
};
