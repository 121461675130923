import { SvgProps } from '../SvgRendererTypes';

export const RvdIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Rvd Icon</title>
      <path
        fill={fillColor}
        d="M17 2H7C6.73478 2 6.48043 2.10536 6.29289 2.29289C6.10536 2.48043 6 2.73478 6 3V21C6 21.2652 6.10536 21.5196 6.29289 21.7071C6.48043 21.8946 6.73478 22 7 22H17C17.2652 22 17.5196 21.8946 17.7071 21.7071C17.8946 21.5196 18 21.2652 18 21V3C18 2.73478 17.8946 2.48043 17.7071 2.29289C17.5196 2.10536 17.2652 2 17 2ZM12 5C12.2967 5 12.5867 5.08797 12.8334 5.2528C13.08 5.41762 13.2723 5.65189 13.3858 5.92597C13.4994 6.20006 13.5291 6.50166 13.4712 6.79264C13.4133 7.08361 13.2704 7.35088 13.0607 7.56066C12.8509 7.77044 12.5836 7.9133 12.2926 7.97118C12.0017 8.02906 11.7001 7.99935 11.426 7.88582C11.1519 7.77229 10.9176 7.58003 10.7528 7.33336C10.588 7.08668 10.5 6.79667 10.5 6.5C10.5 6.10218 10.658 5.72064 10.9393 5.43934C11.2206 5.15804 11.6022 5 12 5ZM17 21H7V10H17V21Z"
      />
      <path
        fill={fillColor}
        d="M12 13.5C12.3956 13.5 12.7822 13.6173 13.1111 13.8371C13.44 14.0568 13.6964 14.3692 13.8478 14.7346C13.9991 15.1001 14.0387 15.5022 13.9616 15.8902C13.8844 16.2781 13.6939 16.6345 13.4142 16.9142C13.1345 17.1939 12.7781 17.3844 12.3902 17.4616C12.0022 17.5387 11.6001 17.4991 11.2346 17.3478C10.8692 17.1964 10.5568 16.94 10.3371 16.6111C10.1173 16.2822 10 15.8956 10 15.5C10 14.9696 10.2107 14.4609 10.5858 14.0858C10.9609 13.7107 11.4696 13.5 12 13.5ZM12 12.5C11.4067 12.5 10.8266 12.6759 10.3333 13.0056C9.83994 13.3352 9.45542 13.8038 9.22836 14.3519C9.0013 14.9001 8.94189 15.5033 9.05764 16.0853C9.1734 16.6672 9.45912 17.2018 9.87868 17.6213C10.2982 18.0409 10.8328 18.3266 11.4147 18.4424C11.9967 18.5581 12.5999 18.4987 13.148 18.2716C13.6962 18.0446 14.1648 17.6601 14.4944 17.1667C14.8241 16.6734 15 16.0933 15 15.5C15 14.7044 14.6839 13.9413 14.1213 13.3787C13.5587 12.8161 12.7956 12.5 12 12.5Z"
      />
    </svg>
  );
};
