import { SvgProps } from '../SvgRendererTypes';

export const LightIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Light Icon</title>
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8C12.077 7.994 12.168 7.991 12.259 7.991C14.33 7.991 16.009 9.67 16.009 11.741C16.009 11.832 16.006 11.922 15.999 12.012C16 14.54 13.32 17.27 13.29 17.29L13 17.58V19H11V17.59L10.71 17.3C10.71 17.3 8 14.54 8 12C7.994 11.923 7.991 11.832 7.991 11.741C7.991 9.67 9.67 7.991 11.741 7.991C11.832 7.991 11.922 7.994 12.012 8.001L12 8ZM12 7C11.914 6.994 11.813 6.991 11.711 6.991C9.104 6.991 6.991 9.104 6.991 11.711C6.991 11.813 6.994 11.913 7.001 12.014C7 15 10 18 10 18V19.5C10 19.776 10.224 20 10.5 20H13.5C13.776 20 14 19.776 14 19.5V18C14 18 17 15 17 12C17.006 11.914 17.009 11.813 17.009 11.711C17.009 9.104 14.896 6.991 12.289 6.991C12.187 6.991 12.087 6.994 11.986 7.001L12 7ZM13.5 21H10.5C10.224 21 10 21.224 10 21.5C10 21.776 10.224 22 10.5 22H13.5C13.776 22 14 21.776 14 21.5C14 21.224 13.776 21 13.5 21Z"
      />
      <path
        fill={fillColor}
        d="M12 3C11.724 3 11.5 3.224 11.5 3.5V5.5C11.5 5.776 11.724 6 12 6C12.276 6 12.5 5.776 12.5 5.5V3.5C12.5 3.224 12.276 3 12 3Z"
      />
      <path
        fill={fillColor}
        d="M19.21 5.29004C19.073 5.29204 18.949 5.34904 18.86 5.44004L17.44 6.85004C17.349 6.94104 17.293 7.06604 17.293 7.20504C17.293 7.48204 17.518 7.70704 17.795 7.70704C17.934 7.70704 18.059 7.65104 18.15 7.56004L19.56 6.15004C19.648 6.06004 19.703 5.93604 19.703 5.80004C19.703 5.52604 19.483 5.30404 19.21 5.30004V5.29004Z"
      />
      <path
        fill={fillColor}
        d="M4.297 5.79004C4.297 5.51604 4.517 5.29404 4.79 5.29004H4.8C4.937 5.29204 5.061 5.34904 5.15 5.44004L6.56 6.85004C6.651 6.94104 6.707 7.06604 6.707 7.20504C6.707 7.48204 6.482 7.70704 6.205 7.70704C6.066 7.70704 5.941 7.65104 5.85 7.56004L4.44 6.14004C4.352 6.05004 4.297 5.92604 4.297 5.79004Z"
      />
      <path
        fill={fillColor}
        d="M20.5 11.5H18.5C18.224 11.5 18 11.724 18 12C18 12.276 18.224 12.5 18.5 12.5H20.5C20.776 12.5 21 12.276 21 12C21 11.724 20.776 11.5 20.5 11.5Z"
      />
      <path
        fill={fillColor}
        d="M17.297 16.79C17.297 16.516 17.517 16.294 17.79 16.29L17.8 16.28C17.937 16.282 18.061 16.339 18.15 16.43L19.56 17.84C19.651 17.931 19.707 18.056 19.707 18.195C19.707 18.472 19.482 18.697 19.205 18.697C19.066 18.697 18.941 18.641 18.85 18.55L17.44 17.14C17.352 17.05 17.297 16.926 17.297 16.79Z"
      />
      <path
        fill={fillColor}
        d="M5.5 11.5H3.5C3.224 11.5 3 11.724 3 12C3 12.276 3.224 12.5 3.5 12.5H5.5C5.776 12.5 6 12.276 6 12C6 11.724 5.776 11.5 5.5 11.5Z"
      />
      <path
        fill={fillColor}
        d="M5.86 16.44C5.949 16.349 6.073 16.292 6.21 16.29V16.3C6.483 16.304 6.703 16.526 6.703 16.8C6.703 16.936 6.648 17.06 6.56 17.15L5.15 18.56C5.059 18.651 4.934 18.707 4.795 18.707C4.518 18.707 4.293 18.482 4.293 18.205C4.293 18.066 4.349 17.941 4.44 17.85L5.86 16.44Z"
      />
    </svg>
  );
};
