import { SvgProps } from '../SvgRendererTypes';

export const RingTvIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Ring Tv Icon</title>
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.38 14.8099L14.85 11.9999L10.38 9.18994V14.8099ZM11.38 10.9999L12.97 11.9999L11.38 12.9999V10.9999Z"
      />
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7C9.24 7 7 9.24 7 12C7 14.76 9.24 17 12 17C14.76 17 17 14.76 17 12C17 9.24 14.76 7 12 7ZM12 16C9.79 16 8 14.21 8 12C8 9.79 9.79 8 12 8C14.21 8 16 9.79 16 12C16 14.21 14.21 16 12 16Z"
      />
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 4H21C21.55 4 22 4.45 22 5V19C22 19.55 21.55 20 21 20H3C2.45 20 2 19.55 2 19V5C2 4.45 2.45 4 3 4ZM3 5V19H21V5H3Z"
      />
    </svg>
  );
};
