import { SvgProps } from '../SvgRendererTypes';

export const NeighborhoodNewIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Neighborhood New Icon</title>
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M14.922 4.802a7.64 7.64 0 0 0-3.038-.579 7.777 7.777 0 0 0-7.078 4.81 7.75 7.75 0 0 0 1.642 8.387.1.1 0 0 0 .129.015.103.103 0 0 0 .042-.123 7.476 7.476 0 0 1-.318-1.038c-.2-.96-.101-1.088-.082-1.257.042-.36.297-.655.78-.903.43-.223.99-.375 1.447-.484a8.049 8.049 0 0 0 .022 1.934c.162 1.048.495 2.02.749 2.492a.102.102 0 0 0 .124.05.102.102 0 0 0 .07-.114c-.097-.62-.409-3.607-.068-4.574.328-.931 1.485-1.054 2.666-1.055 1.181 0 2.359.117 2.666 1.055.278.846.052 3.784-.064 4.535a.103.103 0 0 0 .194.064 8.075 8.075 0 0 0 .728-2.453c.072-.642.08-1.29.022-1.933 1.063.253 2.137.62 2.227 1.387.02.168.073.491-.082 1.256-.08.353-.185.7-.317 1.036a.103.103 0 0 0 .042.123.1.1 0 0 0 .128-.015 7.707 7.707 0 0 0 2.224-5.479 7.591 7.591 0 0 0-.64-3.017 7.906 7.906 0 0 0-4.215-4.12Zm-6.84 7.77a1.345 1.345 0 0 1-.954-2.3 1.352 1.352 0 0 1 2.305.954 1.35 1.35 0 0 1-1.35 1.346ZM12 11.322c-.9 0-1.63-.729-1.63-1.627S11.1 8.07 12 8.07c.9 0 1.63.728 1.63 1.626A1.63 1.63 0 0 1 12 11.322Zm3.917 1.25c-.746 0-1.35-.603-1.35-1.347s.604-1.347 1.35-1.347c.745 0 1.35.603 1.35 1.347a1.35 1.35 0 0 1-1.35 1.347ZM12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10c-.006-5.52-4.48-9.994-10-10Zm9.003 10A9.003 9.003 0 1 1 12 2.997 9.014 9.014 0 0 1 21.003 12Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
