import { SvgProps } from '../SvgRendererTypes';

export const GiftIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill={fillColor}
        d="M20 7.9h-3.61c.7-.58.71-1.92.71-1.92C17.1 4.33 15.77 3 14.12 3h-.09c-.83 0-1.57.41-2.03 1.03C11.54 3.41 10.8 3 9.97 3h-.09C8.23 3 6.9 4.33 6.9 5.98c0 0-.01 1.17.71 1.92H4c-.55 0-1 .45-1 1v2.55c0 .54.43.97.96.99L3.95 20c0 .55.45 1 1 1h14.07c.55 0 1-.45 1-1l-.01-7.56c.55 0 .99-.44.99-.99V8.9c0-.55-.45-1-1-1ZM7.9 5.98C7.9 4.89 8.79 4 9.88 4c0 0 1.6-.06 1.6 1.39v2.5H9.75c-1.02 0-1.85-.83-1.85-1.85v-.06ZM10 20H4.95v-7.53H10V20Zm0-8.55H4V8.9h6v2.55ZM13 20h-2V8.9h2V20Zm-.49-12.11V5.41C12.63 4 14.12 4 14.12 4c1.09 0 1.98.89 1.98 1.98v.06c0 1.02-.83 1.85-1.85 1.85h-1.74ZM14 20v-7.53h5.02v7.52L14 20Zm0-8.55V8.9h6v2.55h-6Z"
      />
    </svg>
  );
};
