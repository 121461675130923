import { SvgProps } from '../SvgRendererTypes';

export const BlogIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Blog Icon</title>
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 11H11V6H6V11ZM7 7H10V10H7V7Z"
      />
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 18H6V13H11V18ZM10 14H7V17H10V14Z"
      />
      <path fill={fillColor} d="M18 13H13V14H18V13Z" />
      <path fill={fillColor} d="M13 15H18V16H13V15Z" />
      <path fill={fillColor} d="M18 17H13V18H18V17Z" />
      <path fill={fillColor} d="M13 6H18V7H13V6Z" />
      <path fill={fillColor} d="M18 8H13V9H18V8Z" />
      <path fill={fillColor} d="M13 10H18V11H13V10Z" />
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 3H4C3.45 3 3 3.45 3 4V20C3 20.55 3.45 21 4 21H20C20.55 21 21 20.55 21 20V4C21 3.45 20.55 3 20 3ZM4 20V4H20V20H4Z"
      />
    </svg>
  );
};
