import { ContentType, Svg, Testid } from '../../types';
import { classes, generateKey } from '../../utils';
import { SvgRenderer } from '../SvgRenderer/SvgRenderer';
import { SvgEnum } from '../SvgRenderer/SvgRendererTypes';
import { Link } from '../common/Link';

import styles from './BlocksContainer.module.scss';

export interface BlocksContainerProps extends ContentType {
  blocks: Array<BlockProps>;
}

interface BlockProps extends ContentType {
  headline: string;
  icon: Svg;
  body: string;
  url: string;
  urlCopy: string;
}

export const BlocksContainer = (props: BlocksContainerProps & Testid) => {
  const {
    blocks,
    'block': blockClass,
    'left-container': leftContainer,
    'link-container': linkContainer,
    'main-icon': mainIcon,
    current
  } = styles;

  return (
    <div className={blocks}>
      {props.blocks?.map((block: BlockProps, index) => (
        <Link
          className={classes(blockClass, {
            [current]:
              block.url === `${window.location.origin}${window.location.pathname}` ||
              window.location.pathname.endsWith(block.url)
          })}
          href={block.url}
          key={generateKey(block.contentType)}
          aria-label={`${block.headline}: ${block.urlCopy}`}
          testid={`${props.testid}-block-${index}`}
          data-href={block.url}
        >
          <div className={leftContainer}>
            <span className={mainIcon}>
              <SvgRenderer name={block.icon.icon} />
            </span>
            <div>
              <div>
                <h2>{block.headline}</h2>
                <p>{block.body}</p>
              </div>
              <div className={linkContainer}>
                <span>{block.urlCopy}</span>
                <SvgRenderer name={SvgEnum.CHEVRON_RIGHT} />
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};
