import { SvgProps } from '../SvgRendererTypes';

export const LogOutIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Log Out Icon</title>
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5008 21H2.99846V4H14.5008C14.7763 4 15 3.776 15 3.5C15 3.224 14.7763 3 14.5008 3H2.99846C2.44731 3 2 3.448 2 4V21C2 21.552 2.44731 22 2.99846 22H14.5008C14.7763 22 15 21.776 15 21.5C15 21.224 14.7763 21 14.5008 21ZM22 12.531L17.76 8.15182C17.669 8.05784 17.544 8 17.405 8C17.128 8 16.903 8.23238 16.903 8.51848C16.903 8.66204 16.959 8.79114 17.05 8.88513L20.05 11.9836H8.5C8.224 11.9836 8 12.2149 8 12.5C8 12.7851 8.224 13.0164 8.5 13.0164H20.08L17.08 16.1149C16.989 16.2089 16.933 16.338 16.933 16.4815C16.933 16.7676 17.158 17 17.435 17C17.574 17 17.699 16.9422 17.79 16.8482L21.32 13.2023L22 12.531Z"
      />
    </svg>
  );
};
