import { SvgProps } from '../SvgRendererTypes';

export const CloseXIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Close Icon</title>
      <path
        fill={fillColor}
        strokeWidth=".175"
        d="M5.154 5.154a.826.826 0 0 0 0 1.168L10.833 12l-5.679 5.678a.826.826 0 0 0 1.168 1.168L12 13.167l5.678 5.679a.826.826 0 0 0 1.168-1.168L13.167 12l5.679-5.678a.826.826 0 0 0-1.168-1.168L12 10.832 6.322 5.154a.826.826 0 0 0-1.168 0Z"
      />
    </svg>
  );
};
