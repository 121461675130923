import { SvgProps } from '../SvgRendererTypes';

export const BundleIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Bundle Icon</title>
      <g>
        <path
          fill={fillColor}
          d="M9.49 10a1.81 1.81 0 1 0 0 3.62 1.81 1.81 0 0 0 0-3.62zm0 2.63c-.45 0-.81-.36-.81-.81 0-.45.36-.81.81-.81s.81.36.81.81c0 .44-.37.81-.81.81z"
        />
        <path
          fill={fillColor}
          d="M21.86 8.58A1.97 1.97 0 0 0 20 7.31h-.99V4c0-.55-.45-1-1-1h-6.98c-.55 0-1 .45-1 1v1.23H8.44c-.55 0-1 .45-1 1V7.5h-1.4c-.55 0-1 .45-1 1v3.11l-.01-.02-2.25 1.76c-.68.54-.94 1.42-.66 2.24.28.84 1.02 1.37 1.88 1.37h1.04V20c0 .55.45 1 1 1h6.79c.55 0 1-.45 1-1v-1h1.59c.55 0 1-.45 1-1v-1.23h1.59c.55 0 1-.45 1-1V13l2.35-2.2c.61-.58.8-1.45.5-2.22zM5.04 15.96H4c-.52 0-.83-.35-.94-.68-.11-.33-.09-.8.33-1.13l1.65-1.29v3.1zM12.83 20H6.04v-3.03l6.78.02h.01V20zm0-4.02h-.02l-6.77-.02V8.5h6.79v7.48zM15.42 18h-1.59v-1.1c.74-.12 1.23-.36 1.59-.61V18zm0-3-.04.04-.16.13c-.32.27-.67.56-1.39.71V8.51c0-.55-.45-1-1-1H8.44V6.24h6.98V15zm2.59.77h-1.59v-.35l1.59-1.49v1.84zm0-3.2-1.59 1.49V6.24c0-.55-.45-1-1-1h-4.39V4h6.98v8.57zm2.67-2.51-1.67 1.57V8.31H20c.5 0 .81.33.93.63.12.32.12.77-.25 1.12z"
        />
      </g>
    </svg>
  );
};
