import { SvgProps } from '../SvgRendererTypes';

export const ChimeIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Chime Icon</title>
      <path
        fill={fillColor}
        d="M10.5 6H13.5C13.6326 6 13.7598 6.05268 13.8536 6.14645C13.9473 6.24022 14 6.36739 14 6.5C14 6.63261 13.9473 6.75978 13.8536 6.85355C13.7598 6.94732 13.6326 7 13.5 7H10.5C10.3674 7 10.2402 6.94732 10.1464 6.85355C10.0527 6.75978 10 6.63261 10 6.5C10 6.36739 10.0527 6.24022 10.1464 6.14645C10.2402 6.05268 10.3674 6 10.5 6Z"
      />
      <path
        fill={fillColor}
        d="M8.5 10H15.5C15.6326 10 15.7598 10.0527 15.8536 10.1464C15.9473 10.2402 16 10.3674 16 10.5C16 10.6326 15.9473 10.7598 15.8536 10.8536C15.7598 10.9473 15.6326 11 15.5 11H8.5C8.36739 11 8.24021 10.9473 8.14645 10.8536C8.05268 10.7598 8 10.6326 8 10.5C8 10.3674 8.05268 10.2402 8.14645 10.1464C8.24021 10.0527 8.36739 10 8.5 10Z"
      />
      <path
        fill={fillColor}
        d="M13.5 14H10.5C10.3674 14 10.2402 14.0527 10.1464 14.1464C10.0527 14.2402 10 14.3674 10 14.5C10 14.6326 10.0527 14.7598 10.1464 14.8536C10.2402 14.9473 10.3674 15 10.5 15H13.5C13.6326 15 13.7598 14.9473 13.8536 14.8536C13.9473 14.7598 14 14.6326 14 14.5C14 14.3674 13.9473 14.2402 13.8536 14.1464C13.7598 14.0527 13.6326 14 13.5 14Z"
      />
      <path
        fill={fillColor}
        d="M9.5 8H14.5C14.6326 8 14.7598 8.05268 14.8536 8.14645C14.9473 8.24021 15 8.36739 15 8.5C15 8.63261 14.9473 8.75979 14.8536 8.85355C14.7598 8.94732 14.6326 9 14.5 9H9.5C9.36739 9 9.24021 8.94732 9.14645 8.85355C9.05268 8.75979 9 8.63261 9 8.5C9 8.36739 9.05268 8.24021 9.14645 8.14645C9.24021 8.05268 9.36739 8 9.5 8Z"
      />
      <path
        fill={fillColor}
        d="M14.5 12H9.5C9.36739 12 9.24021 12.0527 9.14645 12.1464C9.05268 12.2402 9 12.3674 9 12.5C9 12.6326 9.05268 12.7598 9.14645 12.8536C9.24021 12.9473 9.36739 13 9.5 13H14.5C14.6326 13 14.7598 12.9473 14.8536 12.8536C14.9473 12.7598 15 12.6326 15 12.5C15 12.3674 14.9473 12.2402 14.8536 12.1464C14.7598 12.0527 14.6326 12 14.5 12Z"
      />
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3H18C18.2652 3 18.5196 3.10536 18.7071 3.29289C18.8946 3.48043 19 3.73478 19 4V20C19 20.2652 18.8946 20.5196 18.7071 20.7071C18.5196 20.8946 18.2652 21 18 21H6C5.73478 21 5.48043 20.8946 5.29289 20.7071C5.10536 20.5196 5 20.2652 5 20V4C5 3.73478 5.10536 3.48043 5.29289 3.29289C5.48043 3.10536 5.73478 3 6 3ZM6 20H18V4H6V20Z"
      />
    </svg>
  );
};
