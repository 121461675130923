import React, { createContext, useContext } from 'react';
import { Experiments, ExperimentData, Treatment } from './NavContext';

type Event = Record<string, string | number>;

type ExperimentsCtxType = {
  getExperimentData?: (name: Treatment) => ExperimentData | undefined;
  putExperimentMetric?: (data: Event, experiment: ExperimentData) => Promise<void>;
};

const ExperimentsCtx = createContext<ExperimentsCtxType>({
  getExperimentData: undefined,
  putExperimentMetric: undefined
});

type ExperimentsContextProps = {
  children: React.ReactNode;
  experiments?: Experiments;
};

export const ExperimentsProvider: React.FC<ExperimentsContextProps> = ({
  children,
  experiments = {}
}) => {
  function getExperimentData(name: Treatment) {
    if (!experiments[name]) return;

    return {
      feature: name,
      ...experiments[name]
    } as ExperimentData;
  }

  async function putExperimentMetric(data: Event, experiment: ExperimentData) {
    const { entityId, feature } = experiment;
    try {
      await fetch('/treatments/experiments', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          feature,
          events: [
            {
              userDetails: {
                entityId
              },
              details: {
                ...data
              }
            }
          ]
        })
      });
    } catch (err) {
      console.error(`Failed to update metric: ${err}`);
    }
  }

  return (
    <ExperimentsCtx.Provider value={{ getExperimentData, putExperimentMetric }}>
      {children}
    </ExperimentsCtx.Provider>
  );
};

export function useExperiment(name: Treatment, data?: Event) {
  const { getExperimentData, putExperimentMetric } = useContext(ExperimentsCtx);
  const experiment = getExperimentData?.(name);

  return {
    experiment,
    putMetric: async (overrideData?: Event) => {
      const dataToPut = overrideData || data;

      if (dataToPut && experiment) {
        await putExperimentMetric?.(dataToPut, experiment);
      }
    }
  };
}
