import { ContentType, ContentTypes, ShopifyProduct } from '../../types';
import { generateKey } from '../../utils';
import { Link } from '../common/Link';

import styles from './ProductRow.module.scss';

export interface ProductRowProps extends ContentType {
  products: Array<ProductProps>;
}

interface BadgeProps extends ContentType {
  style: 'blue' | 'orange';
  label: string;
}

interface ProductProps extends ContentType {
  contentType: ContentTypes;
  badges?: Array<BadgeProps>;
  product: ShopifyProduct;
}

export const ProductRow = ({ products }: ProductRowProps) => {
  const { 'product-row': productRow, 'product-block': productBlock, badge, spacer } = styles;

  return (
    <div className={productRow}>
      {products?.map(({ product }, index) => {
        const { product: currentProduct } = product;

        return (
          <Link
            href={`/${currentProduct.handle}`}
            className={productBlock}
            key={generateKey(currentProduct.title)}
          >
            {products && products[index] && products[index].badges?.length ? (
              products[index].badges?.map(({ style, label }, index) => {
                return (
                  <span className={`${badge} ${styles[style]}`} key={generateKey(`${index}`)}>
                    {label}
                  </span>
                );
              })
            ) : (
              <span className={spacer} data-testid="spacer" />
            )}
            <img src={currentProduct.images[0].src} alt={currentProduct.images[0].altText} />
            {currentProduct.title}
          </Link>
        );
      })}
    </div>
  );
};
