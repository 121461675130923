import { SvgProps } from '../SvgRendererTypes';

export const RingLogo = (props: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
    >
      <title>Ring Logo Icon</title>
      <path
        strokeWidth="0px"
        fill="#424242"
        d="m999.99,506.14V250.11s0-.08,0-.11c-.08-73.73-60.11-133.73-133.95-133.75-73.78.02-133.83,60.07-133.86,133.9v121.65c0,73.83,60.07,133.9,133.9,133.9,31.78,0,62.06-11.09,86.17-31.4v32.15c-.03,46.67-40.51,86.1-88.39,86.1-26.43,0-52.76-12.02-68.73-31.36-.05-.06-.1-.11-.14-.17-4.56-5.49-11.23-8.63-18.36-8.63-13.16,0-23.87,10.71-23.87,23.87,0,5.45,1.9,10.79,5.34,15.03.04.06.09.12.13.17,25.18,30.57,64.67,48.82,105.63,48.82,75.07,0,136.14-60.07,136.14-133.9,0-.08,0-.15,0-.22Zm-47.74-256v121.65c0,47.51-38.65,86.17-86.17,86.17s-86.17-38.65-86.17-86.2v-121.62c0-47.51,38.65-86.17,86.17-86.17s86.17,38.65,86.17,86.17Z"
      />
      <path
        strokeWidth="0px"
        fill="#424242"
        d="m528.09,116.24c-73.89,0-134.04,60.1-134.1,133.98,0,.04,0,.08,0,.12v220.39c0,13.18,10.72,23.9,23.9,23.9s23.9-10.72,23.9-23.91v-220.39c0-47.58,38.71-86.29,86.29-86.29s86.29,38.71,86.29,86.29v220.39c0,13.18,10.72,23.9,23.9,23.9s23.9-10.72,23.9-23.9v-220.44c-.03-73.91-60.18-134.04-134.1-134.04Z"
      />
      <path
        strokeWidth="0px"
        fill="#424242"
        d="m198.11,140.9c-.08-.06-.16-.12-.24-.18,0,0-.43-.29-.44-.3-22.57-15.8-49.1-24.16-76.39-24.16h-.03c-.12-.01-.31-.02-.43-.02h0C50.74,116.26.03,172.64.01,250c0,.11-.01.23-.01.34v220.39c0,13.18,10.72,23.9,23.9,23.9s23.9-10.72,23.9-23.9v-220.39c0-52.42,28.58-86.29,72.82-86.29,18,0,35.24,5.48,49.87,15.86.09.07.19.13.28.18,3.96,2.67,8.56,4.07,13.33,4.07,13.18,0,23.91-10.72,23.91-23.91,0-7.65-3.7-14.86-9.91-19.36Z"
      />
      <path
        strokeWidth="0px"
        fill="#424242"
        d="m290.08,137.39c-13.15,0-23.85,10.7-23.85,23.85v309.6c0,13.15,10.7,23.85,23.85,23.85s23.85-10.7,23.85-23.85V161.24c0-13.15-10.7-23.85-23.85-23.85Z"
      />
      <path
        strokeWidth="0px"
        fill="#1998d5"
        d="m289.69,0c-20.66,0-37.47,16.81-37.47,37.47s16.81,37.46,37.47,37.46,37.46-16.81,37.46-37.46S310.34,0,289.69,0Z"
      />
    </svg>
  );
};
