import React, { AriaRole } from 'react';
import { classes } from '../../utils';
import styles from './Button.module.scss';
import { Testid } from '../../types';

interface ButtonProps {
  className?: string;
  onClick?: () => void | undefined;
  onMouseEnter?: () => void | undefined;
  onMouseLeave?: () => void | undefined;
  children: React.ReactNode;
  ariaLabel?: string;
  tabIndex?: number;
  role?: AriaRole;
}

export const Button = (props: ButtonProps & Partial<Testid>) => {
  const { 'gn-btn': gnBtn } = styles;

  return (
    <button
      className={classes(gnBtn, props.className)}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      data-testid={props.testid || ''}
      aria-label={props.ariaLabel || ''}
      tabIndex={props.tabIndex || 0}
      role={props.role}
    >
      {props.children}
    </button>
  );
};
